import { applyMiddleware, combineReducers, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'

import account from './account/account'
import accountUsers from './accountUsers/accountUsers'
import choices from './choices/choices'
import language from './language/language'
import products from './products/products'
import user from './user/user'

const rootReducer = combineReducers({
    account,
    accountUsers,
    choices,
    language,
    products,
    user
})

const store = createStore(rootReducer, applyMiddleware(ReduxThunk))

export default store