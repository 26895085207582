import {
    clearAuthTokens,
    getRefreshToken,
    setAuthTokens,
    useAuthTokenInterceptor
} from 'axios-jwt'

import { api } from './index'

export const authenticate = (data) => {
    return api.post('/token-obtain/', data)
        .then((response) => {
            setAuthTokens(combineAuthenticationTokens(response))
            return response.data
        })
}

const combineAuthenticationTokens = (response) => ({
    accessToken: response.data.token,
    refreshToken: response.data.token
})

export const logout = () => {
    clearAuthTokens()
}

export const refreshToken = () => {
    return api.post(
        '/token-refresh/',
        {
            token: getRefreshToken()
        }
    )
        .then((response) => {
            setAuthTokens(combineAuthenticationTokens(response))
            return response.data
        })
}

useAuthTokenInterceptor(
    api,
    {
        headerPrefix: "Bearer ",
        refreshToken
    }
)
