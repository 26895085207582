import { api } from './index'

export const create = () => {
    return api.post('/products/')
        .then((response) => {
            return response.data
        })
}

export const duplicate = (id) => {
    return api.post('/products/' + id + '/duplicate/')
        .then((response) => {
            return response.data
        })
}

export const get = () => {
    return api.get('/products/')
        .then((response) => {
            return response.data
        })
}

export const getById = (id) => {
    return api.get('/products/' + id + '/')
        .then((response) => {
            return response.data
        })
}

export const setArchiveState = (id, archive = true) => {
    return update(id, { state: ((archive) ? 'archived' : 'draft') })
}

export const setPublishState = (id, publish = true) => {
    return api.post(
        '/products/' + id + '/' + ((!publish) ? 'un' : '') + 'publish/'
    )
        .then((response) => {
            return response.data
        })
}

export const update = (id, data) => {
    return api.patch(
        '/products/' + id + '/',
        data
    )
        .then((response) => {
            return response.data
        })
}
