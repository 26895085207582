import store from '../store/store'

import { reference } from './objects'

/**
 * Returns choices data object from state.
 * 
 * @return {string} key - Key in dotted syntax
 * @return {Object} - Key value object
 */
export const get = (key) => {
    const choices = getChoicesData().choices
    const list = {}

    const data = key.split('.').reduce(reference, choices)
    if (Array.isArray(data)) {
        data.forEach((item) => {
            list[item.value] = item.display
        })
    }

    return list
}

/**
 * Returns choices data object from state.
 * 
 * @return {Object} - Choices data object
 */
const getChoicesData = () => {
    return store.getState().choices
}