import { getRefreshToken } from "axios-jwt"

import {
    LOGIN_IN_PROGRESS,
    LOGIN_REQUIRED,
    LOGIN_SUCCESS,
    LOGOUT
} from '../../actions/login'

export const STATE_LOGIN_REQUIRED = 'STATE_LOGIN_REQUIRED'
export const STATE_LOGIN_IN_PROGRESS = 'STATE_LOGIN_IN_PROGRESS'
export const STATE_LOGIN_SUCCESS = 'STATE_LOGIN_SUCCESS'
export const STATE_TRY_LOGIN = 'STATE_TRY_LOGIN'

const hasRefreshToken = getRefreshToken()

const initialState = {
    error: null,
    phase: ((!!hasRefreshToken) ? STATE_TRY_LOGIN : STATE_LOGIN_REQUIRED),
    user: null
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUIRED: {
            return {
                ...state,
                error: action.payload.error,
                phase: STATE_LOGIN_REQUIRED,
                user: null
            }
        }
        case LOGIN_IN_PROGRESS: {
            return {
                ...state,
                error: null,
                phase: STATE_LOGIN_IN_PROGRESS
            }
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                error: null,
                phase: STATE_LOGIN_SUCCESS,
                user: action.payload.user
            }
        }
        case LOGOUT: {
            return {
                ...state,
                phase: STATE_LOGIN_REQUIRED,
                user: null
            }
        }
        default: {
            return state
        }
    }
}

export default userReducer