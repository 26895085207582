import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Alert, Button, Spinner } from 'reactstrap'

import { getById } from '../../api/accounts'

import BasicForm from '../../components/BasicForm'

import EditorLayout from '../../layout/EditorLayout'

const PlanEditorView = (props) => {
    //const [ errorMessage, setErrorMessage ] = useState(false)
    const errorMessage = false
    const [ data, setData ] = useState({
        subscription_type: ''
    });
    const [ currentData, setCurrentData ] = useState({});
    const [ dataFetched, setDataFetched ] = useState(false);
    //const [ processing, setProcessing ] = useState(false)
    const processing = false
    const { t } = useTranslation()
    const account = useSelector(state => state.account.account)

    const isSaveable = () => {
        const {
            subscription_type
        } = data

        return (
            (
                subscription_type !== ''
            ) &&
            (
                subscription_type !== currentData.subscription_type
            )
        )
    }

    const navigateToAccountIndex = useCallback(() => {
        props.history.push('/account')
    }, [props.history])

    const onCancel = () => {
        navigateToAccountIndex()
    }

    const onFieldChange = (key, value) => {
        const newData = { ...data }

        newData[key] = value

        setData(newData)
    }

    useEffect(() => {
        if (!isNaN(account.id) && !dataFetched) {
            getById(account.id)
                .then((data) => {
                    if (data) {
                        setDataFetched(true)
                        setData(data)
        
                        const currentData = { ...data }
                        setCurrentData(currentData)
                    }
                }).catch((_) => {
                    navigateToAccountIndex()
                })
        }
    }, [account.id, dataFetched, navigateToAccountIndex])

    return <EditorLayout
        title={ t('account.plan.edit') }
    >
        <BasicForm
            controls={[
                (
                    (errorMessage !== false) ?
                    (
                        <div key="alert">
                            <Alert color="danger">
                                { t('account.plan.error') }
                            </Alert>
                        </div>
                    ) :
                    null
                ),
                <Button
                    key="cancel-button"
                    className="button-row-button"
                    color="secondary"
                    onClick={ () => onCancel() }
                >
                    { t('common.cancel') }
                </Button>,
                <Button
                    key="save-button"
                    className="button-row-button"
                    color="primary"
                    disabled={ (!isSaveable() || processing) }
                    onClick={ () => console.log('SAVE') }
                >
                    { processing && (
                        <>
                            <Spinner color="secondary" size="sm" />
                            { ' ' }
                        </>
                    )}
                    { t('account.plan.change-plan') }
                </Button>
            ]}
            fields={[
                {
                    disabled: processing,
                    label: t('account.plan.subscription-type'),
                    name: 'subscription_type',
                    type: 'select',
                    value: data['subscription_type'] || '' ,
                    values: {
                        [data['subscription_type']]: data['subscription_type']
                    }
                }
            ]}
            onFieldChange={ onFieldChange }
        />
    </EditorLayout>
}

export default PlanEditorView