import React from 'react'
import { Button, Popover } from 'reactstrap'

const ContextMenuButton = (props) => {
    const {
        children,
        color,
        contextMenuButtonId,
        contextMenuControls,
        contextMenuPlacement,
        contextMenuTrigger,
        isOpen,
        onToggle,
        ...rest
    } = props

    return <div>
        <Button
            id={ contextMenuButtonId }
            data-testid="context-menu-button"
            color={ color || 'secondary' }
            { ...rest }
        >
            { children }
        </Button>
        <Popover
            placement={ contextMenuPlacement || 'bottom' }
            isOpen={ isOpen }
            target={ contextMenuButtonId }
            toggle={ onToggle }
            trigger={ contextMenuTrigger || 'click' }
        >
            { contextMenuControls }
        </Popover>
    </div>
}

export default ContextMenuButton
