import axios from 'axios'

/**
 * API url path.
 * 
 * @const {string}
 */
const API_URL = process.env.REACT_APP_API_BASE_URL

if (!API_URL) throw new Error('API_URL is not defined.');

export const api = axios.create({
    baseURL: API_URL
})
