import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Badge, Button, Col, Row } from 'reactstrap'
import qs from 'qs'

import HowItIsUsedEditorView from './Editors/HowItIsUsedEditorView'
import LandingPageEditorView from './Editors/LandingPageEditorView'
import WhatsInItEditorView from './Editors/WhatsInItEditorView'
import WhatsTheImpactEditorView from './Editors/WhatsTheImpactEditorView'
import WhereIsItFromEditorView from './Editors/WhereIsItFromEditorView'

import { fetchProducts } from '../../actions/products'

import { getById, update, setPublishState } from '../../api/products'

import Card from '../../components/Card'
import InnerNavigation from '../../components/InnerNavigation'
import List from '../../components/List'
import SpinnerButton from '../../components/SpinnerButton'

import {
    base64Encode,
    getMandatoryFieldNames,
    initializeLanguageList,
    isMandatoryFieldsFilled
} from '../../helpers/editor'
import * as languages from '../../helpers/language'
import { getDefaultProductImage } from '../../helpers/defaults'
import { areIdentical, getDifferences } from '../../helpers/objects'

import Layout from '../../layout/Layout'

const categoryAbbreviations = [
    'main',
    'contents',
    'origin',
    'usage',
    'impact'
]
const categories = [
    'landing-page',
    'whats-in-it',
    'where-is-it-from',
    'how-is-it-used',
    'whats-the-impact'
]
const editors = [
    LandingPageEditorView,
    WhatsInItEditorView,
    WhereIsItFromEditorView,
    HowItIsUsedEditorView,
    WhatsTheImpactEditorView
]

const choiceValueKeys = []

const ProductCategoriesView = (props) => {
    const [ editMode, setEditMode ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState(false)
    const [ publishStateErrorMessage, setPublishStateErrorMessage ] = useState(false)
    const [ data, setData ] = useState(null)
    const [ currentData, setCurrentData ] = useState({});
    const [ processing, setProcessing ] = useState(false)
    const [ publishProcessing, setPublishProcessing ] = useState(false)
    const [ selectedLanguages, setSelectedLanguages ] = useState(
        languages.getInitialSelectionList()
    );
    const [ activeEditor, setActiveEditor ] = useState(false)
    const { t } = useTranslation()
    const mainLanguage = useSelector(state => state.language.language)
    const dispatch = useDispatch()

    const isSaveable = () => {
        return !areIdentical(currentData, data)
    }

    const onAddBlock = (blockData) => {
        const newData = { ...data }

        if (!('content_blocks' in newData)) {
            newData.content_blocks = []
        } else {
            newData.content_blocks = [ ...data.content_blocks ]
        }

        newData.content_blocks.push(blockData)

        setData(newData)
    }

    const onBackClick = useCallback(() => {
        if (activeEditor !== false) {
            setActiveEditor(false)
            setData({ ...currentData })
            props.history.goBack()
        } else {
            props.history.push('/products')
        }
    }, [activeEditor, currentData, props.history])

    const onBlockDelete = (key, softDelete = false) => {
        const blocks = [ ...data.content_blocks ]

        if (typeof blocks[key] !== 'undefined') {
            if (softDelete) {
                const blockData = { ...blocks[key] }
                delete blockData.delete

                blocks[key] = blockData
            } else {
                blocks.splice(key, 1)
            }

            onFieldChange('content_blocks', blocks)
        }
    }

    const onBlockFieldChange = (key, value, nullable = false) => {
        const blocks = [ ...data.content_blocks ]
        const blockData = { ...blocks[key[0]] }

        if (nullable && !value) {
            blockData[key[1]] = null
        } else {
            blockData[key[1]] = value
        }

        blocks[key[0]] = blockData

        onFieldChange('content_blocks', blocks)
    }

    const onBlockSwapOrderNumbers = (key1, key2) => {
        const blocks = [ ...data.content_blocks ]
        const firstBlock = { ...blocks[key1] }
        const secondBlock = { ...blocks[key2] }

        firstBlock.order = blocks[key2].order
        secondBlock.order = blocks[key1].order

        blocks[key1] = firstBlock
        blocks[key2] = secondBlock

        onFieldChange('content_blocks', blocks)
    }

    const onEditClick = (categoryId) => {
        props.history.push({
            search: '?editor=' + categoryId
        })
    }

    const onFieldChange = (key, value, nullable = false) => {
        const newData = { ...data }

        if (nullable && !value) {
            newData[key] = null
        } else {
            newData[key] = value
        }

        setData(newData)
    }
    
    const onLanguageSelectionChange = useCallback((language, enabled) => {
        setSelectedLanguages(
            languages.changeSelection(
                { ...selectedLanguages },
                language,
                enabled
            )
        )
    }, [selectedLanguages])

    const onReturnToCategoryView = () => {
        props.history.push({
            search: ''
        })
    }

    const onSave = async () => {
        setErrorMessage(false)
        setProcessing(true)

        const patchData = {}

        await Promise.all(
            getDifferences(currentData, data).map(async (item) => {
                const path = item.path

                if (path[0] === 'content_blocks') {
                    let block = null
                    let preventPush = false
    
                    if (item.kind === 'A') {
                        // New content block
                        block = { ...data[path[0]][item.index] }
                        delete block.id

                        for (const key of Object.keys(block)) {
                            const value = block[key]

                            if (value instanceof File) {
                                block[key] = await base64Encode(value)
                            }
                        }

                    } else if (item.kind === 'E' || item.kind === 'N') {
                        // Existing content block
                        const arrayIndex = path[1]
                        if ('content_blocks' in patchData) {
                            block = patchData.content_blocks.find(
                                (blockItem) => blockItem.arrayIndex === arrayIndex
                            )
                        }
    
                        if (!block) {
                            block = {
                                id: data[path[0]][path[1]].id,
                                arrayIndex
                            }
                        } else {
                            preventPush = true
                        }
                        
                        block[path[2]] = (
                            (data[path[0]][path[1]][path[2]] instanceof File) ?
                            await base64Encode(data[path[0]][path[1]][path[2]]) :
                            data[path[0]][path[1]][path[2]]
                        )
                    }
    
                    if (!('content_blocks' in patchData)) {
                        patchData.content_blocks = []
                    }
    
                    if (!preventPush && block) {
                        patchData.content_blocks.push(block)
                    }
                } else {
                    patchData[item.path[0]] = (
                        (data[item.path[0]] instanceof File) ?
                        await base64Encode(data[item.path[0]]) :
                        data[item.path[0]]
                    )
                }
            })
        )

        update(data.id, patchData)
            .then((product) => {
                setData(product)
                setCurrentData({ ...product })
                setActiveEditor(false)
                onReturnToCategoryView()
                dispatch(fetchProducts())
            }).catch((error) => {
                setErrorMessage(error.response.data)
            }).finally(() => {
                setProcessing(false)
            })
    }

    const onTogglePublishState = () => {
        setPublishStateErrorMessage(false)
        setPublishProcessing(true)

        setPublishState(data.id, (currentData.state !== 'published'))
            .then((product) => {
                setData(product)
                setCurrentData({
                    ...product,
                    content_blocks: [ ...product.content_blocks ]
                })
                dispatch(fetchProducts())
            }).catch((error) => {
                setPublishStateErrorMessage(error.response.data)
            }).finally(() => {
                setPublishProcessing(false)
            })
    }

    const preSelectLanguages = useCallback((fields) => {
        setSelectedLanguages(
            initializeLanguageList(
                { ...selectedLanguages },
                fields,
                choiceValueKeys
            )
        )
    }, [selectedLanguages])

    const renderCategories = () => {
        const categoryCards = []

        categories.forEach((category, id) => {
            const mandatoryFields = getMandatoryFieldNames(
                'products.' + category
            )

            categoryCards.push(
                <Card
                    key={ 'category-card-' + id }
                    controls={[
                        <Button
                            key="edit"
                            color="secondary"
                            onClick={ () => onEditClick(id) }
                        >
                            { t('common.edit') }
                        </Button>
                    ]}
                    description={
                        t('products.categories.' + category + '-description')
                    }
                    sm={ 4 }
                    title={ t('products.categories.' + category) }
                    style={ { minHeight: "310px" } }
                >
                    <p className="text-uppercase mt-5" style={ {fontSize: "0.85rem"} }>
                        { (
                            (
                                (
                                    // Mandatory fields check for category fields
                                    mandatoryFields.length > 0 &&
                                    !isMandatoryFieldsFilled(
                                        mandatoryFields,
                                        data
                                    ) 
                                ) ||
                                (
                                    // Mandatory fields check for content blocks
                                    (
                                        'content_blocks' in data &&
                                        Array.isArray(data.content_blocks)
                                    ) ?
                                    (
                                        data.content_blocks.filter((item) => {
                                            /*
                                                Filters out all the content blocks
                                                that don't belong to the current
                                                category
                                            */
                                            return (
                                                item.position ===
                                                categoryAbbreviations[id]
                                            )
                                        }).findIndex((item) => {
                                            /*
                                                Does isMandatoryFieldsFilled check
                                                for every content block separately
                                            */
                                            const contentBlockMandatoryFields = getMandatoryFieldNames(
                                                'products.content-blocks.' + item.content_type
                                            )

                                            return !isMandatoryFieldsFilled(
                                                contentBlockMandatoryFields,
                                                item
                                            )
                                        }) > -1
                                    ) :
                                    false
                                )
                            ) ?
                            (
                                <>
                                    <Badge color="danger" pill >
                                        &nbsp;
                                    </Badge>
                                    { ' ' }
                                    { t('common.mandatory-content-missing') }
                                </>
                            ) :
                            <>
                                &nbsp;
                            </>
                        )}
                    </p>
                </Card>
            )
        })

        return categoryCards
    }

    useEffect(() => {
        if (!languages.isSelected(selectedLanguages, mainLanguage)) {
            onLanguageSelectionChange(mainLanguage, true)
        }
    }, [mainLanguage, selectedLanguages, onLanguageSelectionChange])

    useEffect(() => {
        const { productid } = props.match.params

        if (!isNaN(productid) && !editMode) {
            setEditMode(true)

            getById(productid)
                .then((product) => {
                    if (product) {
                        preSelectLanguages(product)

                        setData(product)
                        setCurrentData({
                            ...product,
                            content_blocks: [ ...product.content_blocks ]
                        })
                    }
                }).catch((_) => {
                    onBackClick()
                })
        }
    }, [editMode, preSelectLanguages, props.match.params, onBackClick])

    useEffect(() => {
        const queryParameters = qs.parse(
            props.location.search,
            {
                ignoreQueryPrefix: true
            }
        )

        setActiveEditor(
            (
                ('editor' in queryParameters) ?
                parseInt(queryParameters.editor) :
                false
            )
        )
    }, [props.location.search])

    const ActiveEditor = (
        (activeEditor !== false) ?
        editors[activeEditor] :
        null
    )

    return <Layout>
        <InnerNavigation
            onBackClick={ onBackClick }
        />
        { (currentData !== null && data !== null) && (
            <Row>
                <Col sm={ 3 }>
                    <Card
                        shadow
                        style={{minHeight: "520px"}}
                        controls={[
                            (
                                (activeEditor !== false) ?
                                (
                                    <SpinnerButton
                                        key="save"
                                        block
                                        color="primary"
                                        disabled={ !isSaveable() }
                                        processing={ processing }
                                        onClick={ () => onSave() }
                                    >
                                        { t('common.save') }
                                    </SpinnerButton>
                                ) :
                                null
                            ),
                            <SpinnerButton
                                key="publish"
                                block
                                color={ ((activeEditor === false) ? 'primary' : 'secondary') }
                                disabled={ isSaveable() }
                                processing={ publishProcessing }
                                onClick={ () => onTogglePublishState() }
                            >
                                { t(
                                    'products.' +
                                    (
                                        (currentData.state === 'published') ?
                                        'un' :
                                        ''
                                    ) +
                                    'publish'
                                ) }
                            </SpinnerButton>
                        ]}
                        description={ currentData['trade_item_description_' + mainLanguage] }
                        image={(
                            (currentData['product_image_' + mainLanguage]) ?
                            {
                                alt: currentData['description_short_' + mainLanguage] + ' image',
                                src: currentData['product_image_' + mainLanguage]
                            } :
                            getDefaultProductImage()
                        )}
                        isDraft={ (currentData.state === 'draft') }
                        sm={ 12 }
                        title={ (
                            (currentData['description_short_' + mainLanguage]) ?
                            currentData['description_short_' + mainLanguage] :
                            t(
                                'products.unknown-product',
                                { id: currentData.id }
                            )
                        ) }
                    >
                        { (publishStateErrorMessage) && (
                            <Alert color="danger">
                                {
                                    (
                                        typeof publishStateErrorMessage === 'object' &&
                                        'error' in publishStateErrorMessage
                                    ) ?
                                    publishStateErrorMessage.error :
                                    t('products.errors.publish')
                                }
                            </Alert>
                        )}
                    </Card>
                </Col>
                <Col sm={ 9 }>
                    {
                        (
                            (ActiveEditor) ?
                            (
                                <ActiveEditor
                                    errorMessage={ errorMessage }
                                    data={ data }
                                    isSaveable={ isSaveable }
                                    processing={ processing }
                                    selectedLanguages={ selectedLanguages }
                                    onAddBlock={ onAddBlock }
                                    onBlockDelete={ onBlockDelete }
                                    onBlockFieldChange={ onBlockFieldChange }
                                    onBlockSwapOrderNumbers={ onBlockSwapOrderNumbers }
                                    onCancel={ onBackClick }
                                    onFieldChange={ onFieldChange }
                                    onLanguageSelectionChange={ onLanguageSelectionChange }
                                    onSave={ onSave }
                                />
                            ) :
                            (
                                <List>
                                    { renderCategories() }
                                </List>
                            )
                        )
                    }
                </Col>
            </Row>
        )}
    </Layout>
}

export default ProductCategoriesView
