/**
 * Fetch the default product image
 * 
 * @return {Object} - Object containing image src, alttext etc.
 */
export const getDefaultProductImage = () => {
    return {
      src: process.env.PUBLIC_URL + '/product_placeholder.png',
      alt: "Default image; a painted white blank canvas."
    }
}
