import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Badge, Col, Row } from 'reactstrap'

import BasicForm from '../../../components/BasicForm'
import ContentBlockEditor from '../../../components/editor/ContentBlockEditor'
import Controls from '../../../components/editor/Controls'
import Group from '../../../components/editor/Group'
import LanguageSelection from '../../../components/editor/LanguageSelection'

import {
    combineTranslationFields,
    getMandatoryFieldNames,
    isMandatoryFieldsFilled
} from '../../../helpers/editor'

import Editor from '../../../layout/components/Editor'

const LandingPageEditorView = (props) => {
    const { t } = useTranslation()
    useSelector(state => state.choices)

    const getHeaderWithMandatoryIndicator = (subKey, header) => {
        const { data } = props

        const mandatoryFields = getMandatoryFieldNames(
            'products.landing-page.' + subKey
        )

        return <>
            { header }
            { (
                mandatoryFields.length > 0 &&
                !isMandatoryFieldsFilled(mandatoryFields, data)
            ) &&
            (
                <>
                    { ' ' }
                    <Badge color="danger" pill>
                        &nbsp;
                    </Badge>
                </>
            )}
        </>
    }

    const getTranslationFields = (labelTranslationKey, fieldKey, fieldType = 'text', values = {}) => {
        const { errorMessage, data, processing, selectedLanguages } = props

        return combineTranslationFields(
            data,
            processing,
            selectedLanguages,
            labelTranslationKey,
            fieldKey,
            fieldType,
            values,
            'products.landing-page',
            errorMessage
        )
    }

    return <Editor
        description={ t('products.categories.landing-page-description') }
        title={ t('products.categories.landing-page') }
    >
        <Row>
            <Col sm={ 10 }>
                <Group
                    groups={[
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'basic-information',
                                t('products.sub-categories.basic-information')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.trade-item-description',
                                        'trade_item_description'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.description-short',
                                                'description_short'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.functional-name',
                                                'functional_name'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.functional-image',
                                                'functional_image',
                                                'file'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.trade-item-group-identification-code-reference-description',
                                                'trade_item_group_identification_code_reference_description'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.gtin',
                                                'gtin'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.webstore-link',
                                                'webstore_link'
                                            )
                                        )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        },
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'product-description',
                                t('products.sub-categories.product-description')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.colour-description',
                                        'colour_description'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.descriptive-size',
                                                'descriptive_size'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.variant-description',
                                                'variant_description'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.net-content',
                                                'net_content'
                                            )
                                        )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        },
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'story',
                                t('products.sub-categories.story')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.product-story',
                                        'product_story',
                                        'textarea'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.tasting-notes',
                                                'tasting_notes',
                                                'textarea'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.trade-item-marketing-message',
                                                'trade_item_marketing_message',
                                                'textarea'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.short-trade-item-marketing-message',
                                                'short_trade_item_marketing_message',
                                                'textarea'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.product-story-image',
                                                'product_story_image',
                                                'file'
                                            )
                                        )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        },
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'images',
                                t('products.sub-categories.images')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.product-image',
                                        'product_image',
                                        'file'
                                    )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        }
                    ]}
                    onFieldChange={ props.onFieldChange }
                />
                <ContentBlockEditor
                    data={ props.data.content_blocks || [] }
                    errorMessage={ ((props.errorMessage) ? props.errorMessage.content_blocks || [] : false) }
                    errorMessageTranslationKey='products.errors.content-blocks-save'
                    processing={ props.processing }
                    position="main"
                    selectedLanguages={ props.selectedLanguages }
                    onAddBlock={ props.onAddBlock }
                    onBlockDelete={ props.onBlockDelete }
                    onBlockSwapOrderNumbers={ props.onBlockSwapOrderNumbers }
                    onFieldChange={ props.onBlockFieldChange }
                />
                <Controls
                    errorMessage={ props.errorMessage }
                    errorMessageTranslationKey='products.errors.save'
                    isSaveable={ props.isSaveable }
                    processing={ props.processing }
                    onCancel={ props.onCancel }
                    onSave={ props.onSave }
                />
            </Col>
            <Col sm={ 2 }>
                <LanguageSelection
                    value={ props.selectedLanguages }
                    onSelectionChange={ props.onLanguageSelectionChange }
                />
            </Col>
        </Row>
    </Editor>
}

export default LandingPageEditorView
