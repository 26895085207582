import React from 'react';
import { useTranslation } from 'react-i18next'


const NoDataFound = (props) => {
    const { t } = useTranslation()

    return (
        <div className="mt-5 text-primary font-weight-light">
            {t("analytics.no-data")}
        </div>
    );
};

export default NoDataFound;
