import i18next from 'i18next'

import {
    changeSelection,
    isDefault,
    isMain,
    isSelected,
    languages
} from './language'
import { getValue, reference } from './objects'

export const mandatoryFields = {
    products: {
        'content-blocks': {
            'link': [
                'content_banner',
                'content_headline',
                'content_text',
                'content_url'
            ],
            'text': [
                'content_banner',
                'content_headline',
                'content_text'
            ],
            'video': [
                'content_headline',
                'content_text',
                'content_url'
            ]
        },
        'how-is-it-used': [
            'consumer_storage_instructions',
            'consumer_usage_instructions',
            'packaging_recycling_scheme_code'
        ],
        'landing-page': {
            'basic-information': [
                'description_short',
                'functional_name',
                'trade_item_description'
            ],
            'images': [
                'product_image'
            ],
            'product-description': [
                'brand_logo'
            ],
            'story': [
                'short_trade_item_marketing_message',
                'trade_item_marketing_message'
            ]
        },
        'whats-in-it': {
            'ingredients': [
                'ingredient_statement'
            ],
            'product-packaging': [
                'packaging_material_type_code',
                'packaging_recycling_scheme_code'
            ]
        },
        'whats-the-impact': [],
        'where-is-it-from': [
            'manufacturer_party_name',
            'provenance_statement'
        ]
    }
}

/**
 * Converts file to Base64 string
 * 
 * @return {File} file - File obejct
 * @return {Promise} - Promise
 */
export const base64Encode = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = (error) => reject(error)
})

/**
 * Combine all necessary translation fields
 * 
 * @return {Object} data - Editable data
 * @return {boolean} disabled - Disabled state of the fields
 * @return {Object} selectedLanguages - Object containing editor language selections
 * @return {string} labelTranslationKey - Translation key of the label
 * @return {string} fieldKey - Field key
 * @return {string} fieldType - Field type
 * @return {Object} values - Key value pair object containing selectable values (only for fieldType "select")
 * @return {*} mandatoryFieldsKey - Mandatory field key in dotted syntax
 * @return {Object} errorMessages - Object containing editor error messages
 * @return {Array} - Array of combined translation fields
 */
export const combineTranslationFields = (data, disabled, selectedLanguages, labelTranslationKey, fieldKey, fieldType = 'text', values = {}, mandatoryFieldsKey = false, errorMessages = {}) => {
    const fields = []

    Object.values(languages).forEach((language, id) => {
        const abbreviation = '_' + language.abbreviation
        const isFirst = (id === 0)
        const name = fieldKey + abbreviation

        fields.push({
            errorMessage: (
                (typeof errorMessages === 'object' && name in errorMessages) ?
                errorMessages[name] :
                null
            ),
            disabled,
            inline: !isFirst,
            label: i18next.t((
                (isFirst) ?
                labelTranslationKey :
                language.translationKey
            )),
            mandatory: (
                (mandatoryFieldsKey !== false) ?
                    !isMandatoryFieldsFilled(
                    getMandatoryFieldNames(mandatoryFieldsKey),
                    {
                        [name]: data[name]
                    },
                    false
                ) :
                false
            ),
            name,
            type: fieldType,
            value: (
                (data[name] instanceof File) ?
                getValue(data[name], 'name', '') :
                getValue(data, name, '')
            ),
            values,
            visible: isSelected(selectedLanguages, language.abbreviation)
        })
    })

    return fields
}


/**
 * Returns array of mandatory field keys recursively by key.
 * 
 * @return {string} key - Key in dotted syntax
 * @return {Array} - Array on field keys
 */
export const getMandatoryFieldNames = (key) => {
    let keys = []

    const data = key.split('.').reduce(reference, mandatoryFields)
    if (Array.isArray(data)) {
        keys = [ ...data ]
    } else if (typeof data === 'object' && data !== null) {
        Object.values(data).forEach((item) => {
            if (Array.isArray(item)) {
                keys = keys.concat(item)
            }
        })
    }

    return keys
}

/**
 * Initializes language list.
 * 
 * @return {Object} languageList - Object containing editor language selections
 * @return {Object} fields - Key value pair object containing field data
 * @return {Array} excludedKeys - Array of excluded keys
 * @return {Object} - Initialized language list
 */
export const initializeLanguageList = (languageList, fields, excludedKeys = []) => {
    const selectableLanguageAbbreviations = Object.values(languages)
        .filter((language) => {
            return (!isDefault(language) && !isMain(language))
        }).map((language) => {
            return '_' + language.abbreviation
        })

    for (const [key, value] of Object.entries(fields)) {
        if (key.length > 3 && (value !== null && value !== '' && value !== false)) {
            const keyWithoutLanguageSuffix = key.substring(
                0,
                (key.length - 3)
            )

            if (!excludedKeys.includes(keyWithoutLanguageSuffix)) {
                const suffix = key.substring((key.length - 3))
                const abbreviationKey = selectableLanguageAbbreviations
                    .indexOf(suffix)

                if (abbreviationKey > -1) {
                    changeSelection(
                        languageList,
                        suffix.substring(1),
                        true
                    )

                    selectableLanguageAbbreviations.splice(
                        abbreviationKey,
                        1
                    )
                }
            }
        }
    }

    return languageList
}

/**
 * Check if mandatory fields are filled.
 * 
 * @return {Array} mandatoryFields - Array of mandatory field keys
 * @return {Object} values - Values object
 * @return {boolean} performFillCheck - Specifies whether the function should perform fill check
 * @return {boolean} - Check state
 */
export const isMandatoryFieldsFilled = (mandatoryFields, values, performFillCheck = true) => {
    if (
        Array.isArray(mandatoryFields) &&
        typeof values === 'object' &&
        values !== null
    ) {
        return !Object.keys(values)
            .some((key) => {
                const languageSuffix = (
                    (key.length > 3) ?
                    key.substring((key.length - 3)):
                    ''
                )
                const keyWithoutSuffix = (
                    (key.length > 3) ?
                    key.substring(0, (key.length - 3)):
                    ''
                )

                if (
                    languageSuffix !== '' &&
                    isMain(languageSuffix.substring(1)) &&
                    mandatoryFields.includes(keyWithoutSuffix)
                ) {
                    return ((performFillCheck) ? !Boolean(values[key]) : true)
                } else {
                    return false
                }
            }
        )
    }

    return true
}
