import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Spinner } from 'reactstrap'

import { fetchAccount } from '../../actions/accounts'

import { getById, update } from '../../api/accounts'

import BasicForm from '../../components/BasicForm'

import EditorLayout from '../../layout/EditorLayout'

const AccountEditorView = (props) => {
    const [ errorMessage, setErrorMessage ] = useState(false)
    const [ data, setData ] = useState({
        address: '',
        company_name: '',
        phone: ''
    });
    const [ currentData, setCurrentData ] = useState({});
    const [ dataFetched, setDataFetched ] = useState(false);
    const [ processing, setProcessing ] = useState(false)
    const { t } = useTranslation()
    const account = useSelector(state => state.account.account)
    const dispatch = useDispatch()

    const isSaveable = () => {
        const {
            address,
            company_name,
            phone
        } = data

        return (
            (
                address !== '' &&
                company_name !== '' &&
                phone !== ''
            ) &&
            (
                address !== currentData.address ||
                company_name !== currentData.company_name ||
                phone !== currentData.phone
            )
        )
    }

    const navigateToAccountIndex = useCallback(() => {
        props.history.push('/account')
    }, [props.history])

    const onCancel = () => {
        navigateToAccountIndex()
    }

    const onFieldChange = (key, value) => {
        const newData = { ...data }

        newData[key] = value

        setData(newData)
    }

    const onSave = () => {
        setErrorMessage(false)
        setProcessing(true)

        const patchData = {}

        for (const [key, value] of Object.entries(data)) {
            if (data[key] !== currentData[key]) {
                patchData[key] = value
            }
        }

        update(data.id, patchData)
            .then((_) => {
                dispatch(fetchAccount(data.id))
                navigateToAccountIndex()
            }).catch((_) => {
                setErrorMessage(true)
            }).finally(() => {
                setProcessing(false)
            })
    }

    useEffect(() => {
        if (!isNaN(account.id) && !dataFetched) {
            getById(account.id)
                .then((data) => {
                    if (data) {
                        setDataFetched(true)
                        setData(data)
        
                        const currentData = { ...data }
                        setCurrentData(currentData)
                    }
                }).catch((_) => {
                    navigateToAccountIndex()
                })
        }
    }, [account.id, dataFetched, navigateToAccountIndex])

    return <EditorLayout
        title={ t('account.info.edit') }
    >
        <BasicForm
            controls={[
                (
                    (errorMessage !== false) ?
                    (
                        <div key="alert">
                            <Alert color="danger">
                                { t('account.info.error') }
                            </Alert>
                        </div>
                    ) :
                    null
                ),
                <Button
                    key="cancel-button"
                    className="button-row-button"
                    color="secondary"
                    onClick={ () => onCancel() }
                >
                    { t('common.cancel') }
                </Button>,
                <Button
                    key="save-button"
                    className="button-row-button"
                    color="primary"
                    disabled={ (!isSaveable() || processing) }
                    onClick={ () => onSave() }
                >
                    { processing && (
                        <>
                            <Spinner color="secondary" size="sm" />
                            { ' ' }
                        </>
                    )}
                    { t('common.save') }
                </Button>
            ]}
            fields={[
                {
                    disabled: processing,
                    label: t('account.info.company-name'),
                    name: 'company_name',
                    type: 'text',
                    value: data['company_name'] || '' 
                },
                {
                    disabled: processing,
                    label: t('account.info.address'),
                    name: 'address',
                    type: 'text',
                    value: data['address'] || ''          
                },
                {
                    disabled: processing,
                    label: t('account.info.phone'),
                    name: 'phone',
                    type: 'text',
                    value: data['phone'] || '' 
                }
            ]}
            onFieldChange={ onFieldChange }
        />
    </EditorLayout>
}

export default AccountEditorView