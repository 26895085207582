import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ConfirmationModal = (props) => <Modal isOpen={ props.display }>
    <ModalHeader>{ props.header }</ModalHeader>
    <ModalBody>{ props.body }</ModalBody>
    <ModalFooter>
        <Button
            color="primary"
            onClick={ props.onConfirm }
        >
            { props.confirmText }
        </Button>
        { '  ' }
        <Button
            color="secondary"
            onClick={ props.onCancel }
        >
            { props.cancelText }
        </Button>
    </ModalFooter>
</Modal>

export default ConfirmationModal
