import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Col, Spinner, Row } from 'reactstrap'

import { fetchLanguage } from '../../actions/language'

import { get, update } from '../../api/brand'

import BasicForm from '../../components/BasicForm'
import LanguageSelection from '../../components/editor/LanguageSelection'

import * as choicesHelper from '../../helpers/choices'
import {
    base64Encode,
    combineTranslationFields,
    initializeLanguageList
} from '../../helpers/editor'
import * as languages from '../../helpers/language'
import { areIdentical, getDifferences, getValue } from '../../helpers/objects'

import EditorLayout from '../../layout/EditorLayout'

const choiceValueKeys = [
    'communication_channel'
]

const BrandEditorView = (props) => {
    const [ data, setData ] = useState({});
    const [ errorMessage, setErrorMessage ] = useState(false)
    const [ currentData, setCurrentData ] = useState({});
    const [ processing, setProcessing ] = useState(false)
    const [ dataFetched, setDataFetched ] = useState(false);
    const [ selectedLanguages, setSelectedLanguages ] = useState(
        languages.getInitialSelectionList()
    );
    const { t } = useTranslation()
    const mainLanguage = useSelector(state => state.language.language)
    useSelector(state => state.choices)
    const dispatch = useDispatch()

    const getTranslationFields = (labelTranslationKey, fieldKey, fieldType = 'text', values = {}) => {
        return combineTranslationFields(
            data,
            processing,
            selectedLanguages,
            labelTranslationKey,
            fieldKey,
            fieldType,
            values,
            false,
            errorMessage
        )
    }

    const isSaveable = () => {
        return !areIdentical(currentData, data)
    }

    const onCancel = () => {
        setData({ ...currentData })
    }

    const onFieldChange = (key, value, nullable = false) => {
        const newData = { ...data }

        if (nullable && !value) {
            newData[key] = null
        } else {
            newData[key] = value
        }

        setData(newData)
    }

    const onLanguageSelectionChange = useCallback((language, enabled) => {
        setSelectedLanguages(
            languages.changeSelection(
                { ...selectedLanguages },
                language,
                enabled
            )
        )
    }, [selectedLanguages])

    const onSave = async () => {
        setErrorMessage(false)
        setProcessing(true)

        const patchData = {}

        await Promise.all(
            getDifferences(currentData, data).map(async (item) => {
                if (data[item.path[0]] instanceof File) {
                    patchData[item.path[0]] = await base64Encode(
                        data[item.path[0]]
                    )
                } else {
                    patchData[item.path[0]] = data[item.path[0]]
                }
            })
        )

        update(data.id, patchData)
            .then((_) => {
                setCurrentData({ ...data })
                if ('main_language' in patchData) {
                    dispatch(fetchLanguage())
                }
            }).catch((error) => {
                setErrorMessage(error.response.data)
            }).finally(() => {
                setProcessing(false)
            })
    }

    const preSelectLanguages = useCallback((fields) => {
        setSelectedLanguages(
            initializeLanguageList(
                { ...selectedLanguages },
                fields,
                choiceValueKeys
            )
        )
    }, [selectedLanguages])

    useEffect(() => {
        if (!languages.isSelected(selectedLanguages, mainLanguage)) {
            onLanguageSelectionChange(mainLanguage, true)
        }
    }, [mainLanguage, selectedLanguages, onLanguageSelectionChange])

    useEffect(() => {
        if (!dataFetched) {
            get()
                .then((data) => {
                    if (data) {
                        preSelectLanguages(data[0])

                        setDataFetched(true)
                        setData(data[0])

                        const currentData = { ...data[0] }
                        setCurrentData(currentData)
                    }
                }).catch((error) => {
                    //navigateToAccountIndex()
                })
        }
    }, [dataFetched, preSelectLanguages])

    return <EditorLayout
        title={ t('brand.edit') }
    >
        <Row>
            <Col sm={ 10 }>
                <BasicForm
                    controls={[
                        (
                            (errorMessage !== false) ?
                            (
                                <div key="alert">
                                    <Alert color="danger">
                                        { t('brand.error') }
                                    </Alert>
                                </div>
                            ) :
                            null
                        ),
                        <Button
                            key="cancel-button"
                            className="button-row-button"
                            color="secondary"
                            onClick={ () => onCancel() }
                        >
                            { t('common.cancel') }
                        </Button>,
                        <Button
                            key="save-button"
                            className="button-row-button"
                            color="primary"
                            disabled={ (!isSaveable() || processing) }
                            onClick={ () => onSave() }
                        >
                            { processing && (
                                <>
                                    <Spinner color="secondary" size="sm" />
                                    { ' ' }
                                </>
                            )}
                            { t('common.save') }
                        </Button>
                    ]}
                    fields={
                        getTranslationFields(
                            'brand.brand-story',
                            'brand_story',
                            'textarea'
                        )
                            .concat(
                                getTranslationFields(
                                    'brand.brand-story-image',
                                    'brand_story_image',
                                    'file'
                                ),
                                getTranslationFields('brand.url-facebook', 'url_facebook'),
                                getTranslationFields('brand.url-twitter', 'url_twitter'),
                                getTranslationFields('brand.url-instagram', 'url_instagram'),
                                getTranslationFields('brand.brand-name', 'brand_owner_gln'),
                                getTranslationFields('brand.brand-logo', 'brand_logo', 'file'),
                                getTranslationFields('brand.contact-name', 'contact_name'),
                                getTranslationFields('brand.contact-address', 'contact_address'),
                                getTranslationFields(
                                    'brand.communication-channel',
                                    'communication_channel',
                                    'select',
                                    choicesHelper.get('brand.communication_channel')
                                ),
                                getTranslationFields('brand.communication-value', 'communication_value'),
                                {
                                    disabled: processing,
                                    label: t('brand.main-language'),
                                    name: 'main_language',
                                    type: 'select',
                                    value: getValue(data, 'main_language', ''),
                                    values: choicesHelper.get('brand.main_language')
                                }
                            )
                    }
                    onFieldChange={ onFieldChange }
                />
            </Col>
            <Col sm={ 2 }>
                <LanguageSelection
                    value={ selectedLanguages }
                    onSelectionChange={ onLanguageSelectionChange }
                />
            </Col>
        </Row>
    </EditorLayout>
}

export default BrandEditorView
