import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'

import BasicForm from '../../../components/BasicForm'
import ContentBlockEditor from '../../../components/editor/ContentBlockEditor'
import Controls from '../../../components/editor/Controls'
import LanguageSelection from '../../../components/editor/LanguageSelection'

import { combineTranslationFields } from '../../../helpers/editor'

import Editor from '../../../layout/components/Editor'

const WhatsTheImpactEditorView = (props) => {
    const { t } = useTranslation()
    useSelector(state => state.choices)

    const getTranslationFields = (labelTranslationKey, fieldKey, fieldType = 'text', values = {}) => {
        const { errorMessage, data, processing, selectedLanguages } = props

        return combineTranslationFields(
            data,
            processing,
            selectedLanguages,
            labelTranslationKey,
            fieldKey,
            fieldType,
            values,
            'products.whats-the-impact',
            errorMessage
        )
    }

    return <Editor
        description={ t('products.categories.whats-the-impact-description') }
        title={ t('products.categories.whats-the-impact') }
    >
        <Row>
            <Col sm={ 10 }>
                <BasicForm
                    fields={
                        getTranslationFields(
                            'products.fields.social-impact',
                            'social_impact'
                        )
                            .concat(
                                getTranslationFields(
                                    'products.fields.carbon-footprint',
                                    'carbon_footprint'
                                )
                            )
                            .concat(
                                getTranslationFields(
                                    'products.fields.certification-standard',
                                    'certification_standard'
                                )
                            )
                            .concat(
                                getTranslationFields(
                                    'products.fields.certification-value',
                                    'certification_value'
                                )
                            )
                    }
                    onFieldChange={ props.onFieldChange }
                />
                <ContentBlockEditor
                    data={ props.data.content_blocks || [] }
                    errorMessage={ ((props.errorMessage) ? props.errorMessage.content_blocks || [] : false) }
                    errorMessageTranslationKey='products.errors.content-blocks-save'
                    processing={ props.processing }
                    position="impact"
                    selectedLanguages={ props.selectedLanguages }
                    onAddBlock={ props.onAddBlock }
                    onBlockDelete={ props.onBlockDelete }
                    onBlockSwapOrderNumbers={ props.onBlockSwapOrderNumbers }
                    onFieldChange={ props.onBlockFieldChange }
                />
                <Controls
                    errorMessage={ props.errorMessage }
                    errorMessageTranslationKey='products.errors.save'
                    isSaveable={ props.isSaveable }
                    processing={ props.processing }
                    onCancel={ props.onCancel }
                    onSave={ props.onSave }
                />
            </Col>
            <Col sm={ 2 }>
                <LanguageSelection
                    value={ props.selectedLanguages }
                    onSelectionChange={ props.onLanguageSelectionChange }
                />
            </Col>
        </Row>
    </Editor>
}

export default WhatsTheImpactEditorView
