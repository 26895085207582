import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Collapse,
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { logout } from '../../actions/login'
import NavLink from './NavLink'

import { hasPermission, isLoggedIn, permissions } from '../../helpers/permissions'

const Navigation = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const onToggleOpen = () => {
        setIsOpen(!isOpen)
    }

    const activePath = props.location.pathname.split('/')[1]
    const loggedIn = isLoggedIn()

    return <Container
        className="nav-container"
        fluid={ true }
    >
        <Navbar expand="md">
            <NavbarToggler onClick={ onToggleOpen } />
            <Collapse isOpen={ isOpen } navbar>
                <Nav>
                    { loggedIn && (
                        <>
                            <NavItem>
                                <NavLink active={ (activePath === 'analytics') }>
                                    <Link to="/analytics">
                                        <span>
                                            { t('navigation.analytics') }
                                        </span>
                                    </Link>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={ (activePath === 'products') }>
                                    <Link to="/products">
                                        <span>
                                            { t('navigation.products') }
                                        </span>
                                    </Link>
                                </NavLink>
                            </NavItem>
                            { (hasPermission(permissions.editBrand)) && (
                                <NavItem>
                                    <NavLink active={ (activePath === 'brand') }>
                                        <Link to="/brand">
                                            <span>
                                                { t('navigation.brand') }
                                            </span>
                                        </Link>
                                    </NavLink>
                                </NavItem>
                            ) }
                            <NavItem>
                                <NavLink active={ (activePath === 'account') }>
                                    <Link to="/account">
                                        <span>
                                            { t('navigation.account') }
                                        </span>
                                    </Link>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink>
                                    <span
                                        className="logout"
                                        onClick={() => { dispatch(logout()) }}
                                        style={{cursor: "pointer"}}
                                    >
                                        Logout
                                    </span>
                                </NavLink>
                            </NavItem>
                            {/*
                            <NavItem>
                                <NavLink active={ (activePath === 'settings') }>
                                    <Link to="/settings">
                                        <span>
                                            { t('navigation.settings') }
                                        </span>
                                    </Link>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={ (activePath === 'help') }>
                                    <Link to="/help">
                                        <span>
                                            { t('navigation.help') }
                                        </span>
                                    </Link>
                                </NavLink>
                            </NavItem>
                            */}
                        </>
                    )}
                </Nav>
            </Collapse>
            <NavbarBrand>
                <img alt="EXAKT" src="/exakt-logo.svg" />
            </NavbarBrand>
        </Navbar>
    </Container>
}

export default withRouter(Navigation)
