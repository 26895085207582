import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import store from './store/store'
import Routes from './routes'


const App = () => {
  return (
    <Provider store={ store }>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App
