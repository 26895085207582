import { diff } from 'deep-diff'

/**
 * Adds key value pairs (in array format) to the target object
 * 
 * @param {Object} object - Target object
 * @param {Array} keyValuePairs - Key value pairs
 */
export const addValues = (object, keyValuePairs) => {
    keyValuePairs.forEach((item) => {
        object[item[0]] = item[1]
    })
}

/**
 * Check if object are identical.
 * 
 * @param {Object} left - Left object
 * @param {Object} right - Right object
 * @return {boolean} - Identical state
 */
export const areIdentical = (left, right) => {
    return !getDifferences(left, right)
}

/**
 * Returns differences between left and right object
 * 
 * @param {Object} left - Left object
 * @param {Object} right - Right object
 * @return {Array} - Array of difference objects
 */
export const getDifferences = (left, right) => {
    return diff(left, right)
}

/**
 * Returns value from object by key if exists. Otherwise returns default value.
 * 
 * @param {Object} object - Target object
 * @param {*} key - Value key
 * @param {*} defaultValue - Default value
 * @return {*} - Value
 */
export const getValue = (object, key = null, defaultValue = null) => {
    let value = defaultValue

    if (typeof object === 'object' && object !== null) {
        if (key !== null) {
            if (typeof object[key] !== 'undefined' && object[key] !== null) {
                value = object[key]
            }
        } else {
            value = object
        }
    }

    return value
}

/**
 * Returns reference to the piece of data in the object.
 * 
 * @param {Object} object - Target object
 * @param {string} index - Value key
 * @return {Object} - Object containing the piece of data
 */
export const reference = (object, index) => {
    return (
        (object !== null && index in object) ?
        object[index]
        :
        null
    )
}
