import {
    CHOICES_FETCH_FAILED,
    CHOICES_FETCH_IN_PROGRESS,
    CHOICES_FETCH_SUCCESS
} from '../../actions/choices'

export const STATE_CHOICES_FETCH_IN_PROGRESS = 'STATE_CHOICES_FETCH_IN_PROGRESS'
export const STATE_CHOICES_FETCH_SUCCESS = 'STATE_CHOICES_FETCH_SUCCESS'
export const STATE_CHOICES_NOT_FETCHED = 'STATE_CHOICES_NOT_FETCHED'

const initialState = {
    error: null,
    phase: STATE_CHOICES_NOT_FETCHED,
    choices: {}
}

const choicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHOICES_FETCH_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                phase: STATE_CHOICES_NOT_FETCHED,
                choices: {}
            }
        }
        case CHOICES_FETCH_IN_PROGRESS: {
            return {
                ...state,
                error: null,
                phase: STATE_CHOICES_FETCH_IN_PROGRESS
            }
        }
        case CHOICES_FETCH_SUCCESS: {
            return {
                ...state,
                error: null,
                phase: STATE_CHOICES_FETCH_SUCCESS,
                choices: action.payload.choices
            }
        }
        default: {
            return state
        }
    }
}

export default choicesReducer
