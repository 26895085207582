import React, { useEffect, useState } from 'react'

import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'


import { hasPermission, permissions } from '../../helpers/permissions'
import { fetchProducts } from '../../actions/products'
import Layout from '../../layout/Layout'
import { get } from '../../api/brand'

import DashboardHeader from '../../components/analytics/DashboardHeader'


const Dashboard = (props) => {
    const dispatch = useDispatch()
    const [ logo, setLogo ] = useState("");
    const [ logoFetched, setLogoFetched ] = useState(false);
    const { t } = useTranslation()

    const mainLanguage = useSelector(state => state.language.language)

    const activePath = props.location.pathname.split('/')[1]
    const products = useSelector(state => state.products.products)

    // TODO: Don't re-fetch brand endpoint. Rather we should bake in the whole
    // brand object in the initial `fetchLanguage()` redux action.
    useEffect(() => {
        get()
            .then((data) => {
                if (data) {
                    const [ brand ] = data;
                    const logo = brand[`brand_logo_${mainLanguage}`];
                    setLogo(logo);
                    setLogoFetched(true);
                }
            }).catch((_err) => {
                setLogo("")
            })
    }, [logoFetched, mainLanguage])

    useEffect(() => {
        dispatch(fetchProducts())
    }, [dispatch])

    const header = <DashboardHeader
        logo={logo}
        setSlugFilter={props.setSlugFilter}
        products={products}
        activePath={activePath}
        mainLanguage={mainLanguage}
    />;

    const objectIsLoading = (obj) => obj && Object.keys(obj).length === 0;

    // These are used later on in the Analytics pipeline. So to avoid sloppy
    // jumping around & unnecessary re-renders, we'll wait for basic features
    // before even showing the data box spinners.
    const user = useSelector(state => state.user.user)
    const account = useSelector(state => state.account.account)

    const loading = !mainLanguage
        || !products.length
        || objectIsLoading(user)
        || objectIsLoading(account);

    const renderContent = () => {
        if (loading) {
            return t("analytics.loading")
        }

        if (!hasPermission(permissions.viewAnalytics)) {
            return t("analytics.no-permission")
        }

        if (products && products.length) {
            return props.children;
        }

        return t("analytics.no-products");
    }

    return (
        <Layout contentHeader={header}>
            <Container className="dashboard">
                {renderContent()}
            </Container>
        </Layout>
    );
}

export default withRouter(Dashboard);
