import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Alert, Button, Spinner } from 'reactstrap'

import { fetchAccountUsers } from '../../actions/accounts'

import { create, getById, update } from '../../api/users'

import BasicForm from '../../components/BasicForm'

import EditorLayout from '../../layout/EditorLayout'

const UserEditorView = (props) => {
    const [ editMode, setEditMode ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState(false)
    const [ data, setData ] = useState({
        can_add_products: false,
        can_archive_products: false,
        can_edit_brand: false,
        can_view_analytics: false,
        company: '',
        email: '',
        first_name: '',
        last_name: '',
        position: '',
        username: ''
    });
    const [ currentData, setCurrentData ] = useState({});
    const [ processing, setProcessing ] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isSaveable = () => {
        const {
            can_add_products,
            can_archive_products,
            can_edit_brand,
            can_view_analytics,
            email,
            first_name,
            last_name,
            password
        } = data

        return (
            (
                email !== '' &&
                first_name !== '' &&
                last_name !== ''
            ) &&
            (
                editMode ||
                (
                    typeof password !== 'undefined' &&
                    password !== ''
                )
            ) &&
            (
                !editMode ||
                (
                    email !== currentData.email ||
                    first_name !== currentData.first_name ||
                    last_name !== currentData.last_name ||
                    can_add_products !== currentData.can_add_products ||
                    can_archive_products !== currentData.can_archive_products ||
                    can_edit_brand !== currentData.can_edit_brand ||
                    can_view_analytics !== currentData.can_view_analytics
                )
            )
        )
    }

    const navigateToUsersList = useCallback(() => {
        props.history.push('/account/users')
    }, [props.history])

    const onCancel = () => {
        navigateToUsersList()
    }

    const onFieldChange = (key, value) => {
        const newData = { ...data }

        if (Array.isArray(key)) {
            newData[key[1]] = value
        } else {
            newData[key] = value
        }

        setData(newData)
    }

    const onSave = () => {
        setErrorMessage(false)
        setProcessing(true)

        if (editMode) {
            const patchData = {}

            for (const [key, value] of Object.entries(data)) {
                if (data[key] !== currentData[key]) {
                    patchData[key] = value
                }
            }

            update(data.id, patchData)
                .then((_) => {
                    dispatch(fetchAccountUsers())
                    navigateToUsersList()
                }).catch((_) => {
                    setErrorMessage(true)
                }).finally(() => {
                    setProcessing(false)
                })
        } else {
            create(data)
                .then((_) => {
                    dispatch(fetchAccountUsers())
                    navigateToUsersList()
                }).catch((_) => {
                    setErrorMessage(true)
                }).finally(() => {
                    setProcessing(false)
                })
        }
    }

    useEffect(() => {
        const { userid } = props.match.params

        if (!isNaN(userid) && !editMode) {
            getById(userid)
                .then((data) => {
                    const selectedUser = data

                    if (selectedUser) {
                        setEditMode(true)
                        setData(selectedUser)
        
                        const currentSelectedUser = { ...selectedUser }
                        setCurrentData(currentSelectedUser)
                    }
                }).catch((_) => {
                    navigateToUsersList()
                })
        }
    }, [editMode, navigateToUsersList, props.match.params])

    return <EditorLayout
        title={ t('account.users.' + ((editMode) ? 'edit' : 'create')) }
    >
        <BasicForm
            controls={[
                (
                    (errorMessage !== false) ?
                    (
                        <div key="alert">
                            <Alert color="danger">
                                { t('account.users.error') }
                            </Alert>
                        </div>
                    ) :
                    null
                ),
                <Button
                    key="cancel-button"
                    className="button-row-button"
                    color="secondary"
                    onClick={ () => onCancel() }
                >
                    { t('common.cancel') }
                </Button>,
                <Button
                    key="save-button"
                    className="button-row-button"
                    color="primary"
                    disabled={ (!isSaveable() || processing) }
                    onClick={ () => onSave() }
                >
                    { processing && (
                        <>
                            <Spinner color="secondary" size="sm" />
                            { ' ' }
                        </>
                    )}
                    { t('common.save') }
                </Button>
            ]}
            fields={[
                {
                    disabled: processing,
                    label: t('account.users.first-name'),
                    name: 'first_name',
                    type: 'text',
                    value: data['first_name'] || '' 
                },
                {
                    disabled: processing,
                    label: t('account.users.last-name'),
                    name: 'last_name',
                    type: 'text',
                    value: data['last_name'] || ''                
                },
                {
                    disabled: processing,
                    label: t('account.users.email'),
                    name: 'email',
                    type: 'email',
                    value: data['email'] || '' 
                },
                {
                    disabled: processing,
                    label: t('account.users.password'),
                    name: 'password',
                    type: 'password',
                    visible: !editMode,
                    value: data['password'] || '' 
                },
                {
                    disabled: processing,
                    label: t('account.users.user-rights.header'),
                    subLabels: [
                        t('account.users.user-rights.viewAnalytics'),
                        t('account.users.user-rights.addProducts'),
                        t('account.users.user-rights.archiveProducts'),
                        t('account.users.user-rights.editBrand')
                    ],
                    name: 'userRights',
                    type: 'checkbox',
                    value: {
                        'can_view_analytics': data['can_view_analytics'],
                        'can_add_products': data['can_add_products'],
                        'can_archive_products': data['can_archive_products'],
                        'can_edit_brand': data['can_edit_brand']
                    }
                }
            ]}
            onFieldChange={ onFieldChange }
        />
    </EditorLayout>
}

export default UserEditorView