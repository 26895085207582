import React from 'react';
import { CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next'


const TeaserBox = (props) => {

    const { boxNames, } = props;

    const { t } = useTranslation()

    return (
        <>
            <CardTitle className="mt-3 mb-0 pl-4">
                <span
                    className="font-weight-light text-uppercase"
                    style={{fontSize: "0.9rem"}}
                >
                    {t('analytics.plan-starter')}
                </span>
            </CardTitle>
            <CardBody className={"p-4 font-weight-light overflow-auto"}>
                <div className="mb-3">
                    {t('analytics.upgrade-cta')}
                </div>
                <div style={{fontSize: "0.9rem"}}>
                    {boxNames.map((name) =>
                        <div className="my-1">{name}</div>
                    )}
                </div>
            </CardBody>
        </>
    );
}

export default TeaserBox;
