import React from 'react'
import { Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const CardState = (props) => {
    const { t } = useTranslation()

    const translateKey = 'card.state.' + ((props.isDraft) ? 'draft' : 'active')
    const stateClassName = 'card-state card-state-' + ((props.isDraft) ? 'draft' : 'active')

    return <Col
        className="align-items-center d-flex"
        data-testid="card-state"
        sm="6"
    >
        <span className={ stateClassName }>
            { t(translateKey) }
        </span>
    </Col>
}
export default CardState
