import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button } from 'reactstrap'

import { fetchProducts } from '../../actions/products'

import { create, duplicate, setArchiveState } from '../../api/products'

import { hasPermission, permissions } from '../../helpers/permissions'
import { getDefaultProductImage } from '../../helpers/defaults'

import Card from '../../components/Card'
import List from '../../components/List'
import SpinnerButton from '../../components/SpinnerButton'

import Layout from '../../layout/Layout'

const ProductListView = (props) => {
    const [ errorMessage, setErrorMessage ] = useState(false)
    const [ addProductProcessing, setAddProductProcessing ] = useState(false)
    const [ archiveProductProcessing, setArchiveProductProcessing ] = useState(false)
    const { t } = useTranslation()
    const mainLanguage = useSelector(state => state.language.language)
    const products = useSelector(state => state.products.products)
    const dispatch = useDispatch()

    const onEditClick = (productId) => {
        props.history.push('/products/' + encodeURIComponent(productId))
    }

    const onAddClick = () => {
        if (hasPermission(permissions.addProducts)) {
            setAddProductProcessing(true)
            setErrorMessage(false)
    
            create()
                .then((product) => {    
                    dispatch(fetchProducts())
                    onEditClick(product.id)
                }).catch((error) => {
                    setErrorMessage(error.response.data)
                    setAddProductProcessing(false)
                })
        }
    }

    const onDuplicateClick = (productId) => {
        if (hasPermission(permissions.addProducts)) {
            setAddProductProcessing(true)
            setErrorMessage(false)
    
            duplicate(productId)
                .then((product) => {    
                    dispatch(fetchProducts())
                    onEditClick(product.id)
                }).catch((error) => {
                    setErrorMessage(error.response.data)
                    setAddProductProcessing(false)
                })
        }
    }

    const onSetArchiveStateClick = (productId, archiveState) => {
        if (hasPermission(permissions.archiveProducts)) {
            setArchiveProductProcessing(true)
            setErrorMessage(false)
    
            setArchiveState(productId, archiveState)
                .then(() => {
                    dispatch(fetchProducts())
                }).catch((error) => {
                    setErrorMessage(error.response.data)
                }).finally(() => {
                    setArchiveProductProcessing(false)
                })
        }
    }

    useEffect(() => {
        dispatch(fetchProducts())
    }, [dispatch])

    return <Layout>
        { (errorMessage) && (
            <Alert color="danger">
                { t(errorMessage) }
            </Alert>
        )}
        <List>
            { products.map((product, id) => <Card
                shadow
                key={ id }
                contextMenuId={ id }
                contextMenuControls={[
                    (
                        (hasPermission(permissions.archiveProducts)) ?
                        (
                            <div key="duplicate">
                                <Button
                                    block
                                    color="secondary"
                                    disabled={ addProductProcessing }
                                    onClick={ () => onDuplicateClick(product.id) }
                                >
                                    { t('products.duplicate') }
                                </Button>
                            </div>
                        ) :
                        null
                    ),
                    (
                        (false && hasPermission(permissions.archiveProducts)) ?
                        (
                            <div key="archive">
                                <Button
                                    block
                                    color="danger"
                                    disabled={ archiveProductProcessing }
                                    onClick={ () => onSetArchiveStateClick(product.id, (product.state !== 'archived')) }
                                >
                                    { t('products.archive') }
                                </Button>
                            </div>
                        ) :
                        null
                    )
                ]}
                controls={[
                    <Button
                        key="edit"
                        color="secondary"
                        onClick={ () => onEditClick(product.id) }
                    >
                        { t('card.controls.edit') }
                    </Button>
                ]}
                description={ product['trade_item_description_' + mainLanguage] }
                image={(
                    (product['product_image_' + mainLanguage]) ?
                    {
                        alt: product['description_short_' + mainLanguage] + ' image',
                        src: product['product_image_' + mainLanguage]
                    } :
                    getDefaultProductImage()
                )}
                isDraft={ product.state === 'draft' }
                title={ (
                    (product['description_short_' + mainLanguage]) ?
                    product['description_short_' + mainLanguage] :
                    t(
                        'products.unknown-product',
                        { id: product.id }
                    )
                ) }
            />) }
            { (hasPermission(permissions.addProducts)) && (
                <Card
                    shadow
                    centerControls
                    controls={[
                        <SpinnerButton
                            key="edit"
                            color="primary"
                            processing={ addProductProcessing }
                            onClick={ () => onAddClick() }
                        >
                            { t('products.add') }
                        </SpinnerButton>
                    ]}
                    title="&nbsp;"
                    image={getDefaultProductImage()}
                />
            )}
        </List>
    </Layout>
}

export default ProductListView
