import {
    PRODUCT_LIST_FETCH_FAILED,
    PRODUCT_LIST_FETCH_IN_PROGRESS,
    PRODUCT_LIST_FETCH_SUCCESS
} from '../../actions/products'

export const STATE_PRODUCT_LIST_FETCH_IN_PROGRESS = 'STATE_PRODUCT_LIST_FETCH_IN_PROGRESS'
export const STATE_PRODUCT_LIST_FETCH_SUCCESS = 'STATE_PRODUCT_LIST_FETCH_SUCCESS'
export const STATE_PRODUCT_LIST_NOT_FETCHED = 'STATE_PRODUCT_LIST_NOT_FETCHED'

const initialState = {
    error: null,
    phase: STATE_PRODUCT_LIST_NOT_FETCHED,
    products: []
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LIST_FETCH_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                phase: STATE_PRODUCT_LIST_NOT_FETCHED,
                products: []
            }
        }
        case PRODUCT_LIST_FETCH_IN_PROGRESS: {
            return {
                ...state,
                error: null,
                phase: STATE_PRODUCT_LIST_FETCH_IN_PROGRESS
            }
        }
        case PRODUCT_LIST_FETCH_SUCCESS: {
            return {
                ...state,
                error: null,
                phase: STATE_PRODUCT_LIST_FETCH_SUCCESS,
                products: action.payload.products
            }
        }
        default: {
            return state
        }
    }
}

export default productsReducer
