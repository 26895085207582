import { api } from './index'


export const get = () => {
    return api.get('/brands/')
        .then((response) => {
            return response.data
        })
}

export const update = (id, data) => {
    return api.patch(
        '/brands/' + id + '/',
        data
    )
        .then((response) => {
            return response.data
        })
}
