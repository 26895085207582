
export const formatDecimal = (value, points) => {
    if (!value) { return "0" }

    return value.toFixed(points);
}


export const formatTime = (time) => {
    if (!time) { return "" }

    const mins = (time / 60).toFixed(0);
    const secs = (time % 60).toFixed(0);
    const minPad = mins.length === 1 ? "0" : "";
    const secPad = secs.length === 1 ? "0" : "";

    return `${minPad}${mins}:${secPad}${secs}`;
}


export const formatPercent = (precision) => {
    return (value) => {
        if (!value) { return "0%" }

        return `${formatDecimal(value, precision)}%`;
    }
}
