import {
    ACCOUNT_USERS_FETCH_FAILED,
    ACCOUNT_USERS_FETCH_IN_PROGRESS,
    ACCOUNT_USERS_FETCH_SUCCESS
} from '../../actions/accounts'

export const STATE_ACCOUNT_USERS_FETCH_IN_PROGRESS = 'STATE_ACCOUNT_USERS_FETCH_IN_PROGRESS'
export const STATE_ACCOUNT_USERS_FETCH_SUCCESS = 'STATE_ACCOUNT_USERS_FETCH_SUCCESS'
export const STATE_ACCOUNT_USERS_NOT_FETCHED = 'STATE_ACCOUNT_USERS_NOT_FETCHED'

const initialState = {
    error: null,
    phase: STATE_ACCOUNT_USERS_NOT_FETCHED,
    users: []
}

const accountUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_USERS_FETCH_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                phase: STATE_ACCOUNT_USERS_NOT_FETCHED,
                users: []
            }
        }
        case ACCOUNT_USERS_FETCH_IN_PROGRESS: {
            return {
                ...state,
                error: null,
                phase: STATE_ACCOUNT_USERS_FETCH_IN_PROGRESS
            }
        }
        case ACCOUNT_USERS_FETCH_SUCCESS: {
            return {
                ...state,
                error: null,
                phase: STATE_ACCOUNT_USERS_FETCH_SUCCESS,
                users: action.payload.users
            }
        }
        default: {
            return state
        }
    }
}

export default accountUsersReducer
