import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import InfoBox from './InfoBox'

import { DEFAULT_DATES } from '../../api/analytics';


const SingleValueDisplay = (props) => {
    const {
        title,
        isUniqueContent,
        productFilter,
        headers,
        fontSize,
        valueFetcher,
        valueFormatter,
        overlay,
    } = props;

    const [ values, setValues ] = useState({left: "", right: ""});
    const [ loading, setLoading ] = useState(false);
    const [ loadingError, setLoadingError ] = useState(false);
    const [ dateFilter, setDateFilter ] = useState(DEFAULT_DATES);

    const fetchValues = () => {
        setLoading(true);
        setLoadingError(false);
        valueFetcher(productFilter, dateFilter)
            .then((data) => {
                if (data) {
                    setValues(data);
                }
                setLoading(false);
                setLoadingError(false);
            }).catch((err) => {
                console.error(err)
                setLoadingError(true);
            })
    }
    useEffect(fetchValues, [productFilter, dateFilter])

    const { left, right } = values;

    const validLeft = (typeof left === 'string' || typeof left === 'number') && left;
    const validRight = (typeof right === 'string' || typeof right === 'number') && right;

    // TODO: It's 2020, make the inner box here an actual flexbox.
    return (
        <InfoBox
            title={title}
            error={loadingError}
            loading={loading}
            noData={!(validLeft && validRight)}
            isUniqueContent={isUniqueContent}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            overlay={overlay}
        >
            <div className="mt-5 px-3">
                <Row
                    style={{fontSize: "3rem", lineHeight: "0.9"}}
                    className="font-weight-light no-gutters"
                >
                    <Col className="border-right py-2 pt-3">
                        {valueFormatter(left)}
                    </Col>
                    <Col className="text-dark py-2 pt-3">
                        {valueFormatter(right)}
                    </Col>
                </Row>
                <Row
                    style={{fontSize: fontSize || "1.5rem", lineHeight: "0.9"}}
                    className="font-weight-light no-gutters text-uppercase"
                >
                    <Col className="border-right py-2">
                        {headers.left}
                    </Col>
                    <Col className="text-dark py-2">
                        {headers.right}
                    </Col>
                </Row>
            </div>
        </InfoBox>
    );
}

export default SingleValueDisplay;
