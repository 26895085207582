import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Badge, Col, Row } from 'reactstrap'

import BasicForm from '../../../components/BasicForm'
import ContentBlockEditor from '../../../components/editor/ContentBlockEditor'
import Controls from '../../../components/editor/Controls'
import Group from '../../../components/editor/Group'
import LanguageSelection from '../../../components/editor/LanguageSelection'

import {
    combineTranslationFields,
    getMandatoryFieldNames,
    isMandatoryFieldsFilled
} from '../../../helpers/editor'

import Editor from '../../../layout/components/Editor'

const WhatsInItEditorView = (props) => {
    const { t } = useTranslation()
    useSelector(state => state.choices)

    const getHeaderWithMandatoryIndicator = (subKey, header) => {
        const { data } = props

        const mandatoryFields = getMandatoryFieldNames(
            'products.whats-in-it.' + subKey
        )

        return <>
            { header }
            { (
                mandatoryFields.length > 0 &&
                !isMandatoryFieldsFilled(mandatoryFields, data)
            ) &&
            (
                <>
                    { ' ' }
                    <Badge color="danger" pill>
                        &nbsp;
                    </Badge>
                </>
            )}
        </>
    }

    const getTranslationFields = (labelTranslationKey, fieldKey, fieldType = 'text', values = {}) => {
        const { errorMessage, data, processing, selectedLanguages } = props

        return combineTranslationFields(
            data,
            processing,
            selectedLanguages,
            labelTranslationKey,
            fieldKey,
            fieldType,
            values,
            'products.whats-in-it',
            errorMessage
        )
    }

    return <Editor
        description={ t('products.categories.whats-in-it-description') }
        title={ t('products.categories.whats-in-it') }
    >
        <Row>
            <Col sm={ 10 }>
                <Group
                    groups={[
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'ingredients',
                                t('products.sub-categories.ingredients')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.ingredient-statement',
                                        'ingredient_statement',
                                        'textarea'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.inci',
                                                'inci',
                                                'textarea'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.nutrient-type-code',
                                                'nutrient_type_code',
                                                'text',
                                                {}
                                            )
                                        )
                                        /*.concat(
                                            getTranslationFields(
                                                'products.fields.quantity-contained',
                                                'quantity_contained',
                                                'number'
                                            )
                                        )*/
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.compulsory-additive-label-information',
                                                'compulsory_additive_label_information',
                                                'textarea'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.organic-trade-item-code',
                                                'organic_trade_item_code'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.organic-claim-agency-name',
                                                'organic_claim_agency_name'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.organic-certification-identification',
                                                'organic_certification_identification'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.organic-certification-effective-start-date-time',
                                                'organic_certification_effective_start_date_time',
                                                'datetime-local'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.organic-certification-effective-end-date-time',
                                                'organic_certification_effective_end_date_time',
                                                'datetime-local'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.genetically-modified-declaration-code',
                                                'genetically_modified_declaration_code',
                                                'checkbox'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.does-trade-item-contain-pesticide',
                                                'does_trade_item_contain_pesticide',
                                                'checkbox'
                                            )
                                        )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        },
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'allergens',
                                t('products.sub-categories.allergens')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.additive-name',
                                        'additive_name'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.allergen-statement',
                                                'allergen_statement',
                                                'textarea'
                                            )
                                        )
                                        /*.concat(
                                            getTranslationFields(
                                                'products.fields.allergen-type-code',
                                                'allergen_type_code'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.level-of-containment-code-type',
                                                'level_of_containment_code_type'
                                            )
                                        )*/
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        },
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'suitability',
                                t('products.sub-categories.suitability')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.diet-type-description',
                                        'diet_type_description',
                                        'textarea'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.nutritional-claim-type-code',
                                                'nutritional_claim_type_code'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.user-type',
                                                'user_type',
                                                'textarea'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.user-group',
                                                'user_group',
                                                'textarea'
                                            )
                                        )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        },
                        {
                            header: getHeaderWithMandatoryIndicator(
                                'product-packaging',
                                t('products.sub-categories.product-packaging')
                            ),
                            content: <BasicForm
                                fields={
                                    getTranslationFields(
                                        'products.fields.packaging-material-type-code',
                                        'packaging_material_type_code'
                                    )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.sustainability-feature-code',
                                                'sustainability_feature_code'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.packaging-recycling-scheme-code',
                                                'packaging_recycling_scheme_code'
                                            )
                                        )
                                        .concat(
                                            getTranslationFields(
                                                'products.fields.is-packaging-material-recoverable',
                                                'is_packaging_material_recoverable',
                                                'checkbox'
                                            )
                                        )
                                }
                                onFieldChange={ props.onFieldChange }
                            />
                        }
                    ]}
                />
                <ContentBlockEditor
                    data={ props.data.content_blocks || [] }
                    errorMessage={ ((props.errorMessage) ? props.errorMessage.content_blocks || [] : false) }
                    errorMessageTranslationKey='products.errors.content-blocks-save'
                    processing={ props.processing }
                    position="contents"
                    selectedLanguages={ props.selectedLanguages }
                    onAddBlock={ props.onAddBlock }
                    onBlockDelete={ props.onBlockDelete }
                    onBlockSwapOrderNumbers={ props.onBlockSwapOrderNumbers }
                    onFieldChange={ props.onBlockFieldChange }
                />
                <Controls
                    errorMessage={ props.errorMessage }
                    errorMessageTranslationKey='products.errors.save'
                    isSaveable={ props.isSaveable }
                    processing={ props.processing }
                    onCancel={ props.onCancel }
                    onSave={ props.onSave }
                />
            </Col>
            <Col sm={ 2 }>
                <LanguageSelection
                    value={ props.selectedLanguages }
                    onSelectionChange={ props.onLanguageSelectionChange }
                />
            </Col>
        </Row>
    </Editor>
}

export default WhatsInItEditorView
