import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { Row, Collapse, Button } from 'reactstrap';


const Accordion = (props) => {

    const { title } = props;

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const caret = isOpen ? faCaretDown : faCaretLeft;

    return <>
        <Row
            className="mb-4 mt-4 justify-content-between align-items-center"
            style={{borderBottom: "1px solid black", marginLeft: "-3rem", marginRight: "-3rem"}}
        >
            <h6
                className="d-inline-block font-weight-bold mb-0"
            >
                {title}
            </h6>
            <Button
                color="secondary"
                onClick={toggle}
                className="shadow-none border-0"
                style={{background: "none"}}
            >
                <FontAwesomeIcon icon={caret} />
            </Button>
        </Row>
        <Collapse isOpen={isOpen}>
            {props.children}
        </Collapse>
    </>
};

export default Accordion;
