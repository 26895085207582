import * as api from '../api/brand'

export const LANGUAGE_FETCH_FAILED = 'language/LANGUAGE_FETCH_FAILED'
export const LANGUAGE_FETCH_IN_PROGRESS = 'language/LANGUAGE_FETCH_IN_PROGRESS'
export const LANGUAGE_FETCH_SUCCESS = 'language/LANGUAGE_FETCH_SUCCESS'

/**
 * Fetch language action.
 * 
 * @return {Object}
 */
export const fetchLanguage = () => {
    return (dispatch) => {
        dispatch(startLanguageFetching())
        return api.get()
            .then((data) => {
                if (0 in data && 'main_language' in data[0]) {
                    dispatch(languageFetchSuccess(data[0].main_language))                 
                } else {
                    dispatch(languageFetchFail({}))
                }
            }).catch((error) => {
                dispatch(languageFetchFail(error))
            })
    }
}

/**
 * Language fetch fail action.
 * 
 * @param {Object} error - Error object
 * @return {Object}
 */
const languageFetchFail = (error) => ({
    type: LANGUAGE_FETCH_FAILED,
    payload: {
        error
    }
})

/**
 * Language fetch success action.
 * 
 * @param {Object} language - Language object
 * @return {Object}
 */
const languageFetchSuccess = (language) => ({
    type: LANGUAGE_FETCH_SUCCESS,
    payload: {
        language
    }
})


/**
 * Start language fetching action.
 * 
 * @return {Object}
 */
const startLanguageFetching = () => ({
    type: LANGUAGE_FETCH_IN_PROGRESS
})
