import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import InfoBox from './InfoBox'

import { DEFAULT_DATES } from '../../api/analytics';


const calcTrendPercent = (value, pastValue) => {
    if (!value || !pastValue) {
        return 0;
    }
    return (value - pastValue) / pastValue * 100;
}

const SingleValueDisplay = (props) => {
    const {
        text,
        title,
        isUniqueContent,
        overlay,
        productFilter,

        valueFetcher,
        valueFormatter,
    } = props;

    const pastValue = null;
    const pastDate = null;

    const [ value, setValue ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ loadingError, setLoadingError ] = useState(false);
    const [ dateFilter, setDateFilter ] = useState(DEFAULT_DATES);

    const fetchValue = () => {
        setLoading(true);
        setLoadingError(false);
        valueFetcher(productFilter, dateFilter)
            .then((data) => {
                if (data) {
                    const [ val ] = data.values;
                    setValue(valueFormatter(val));
                }
                setLoading(false);
                setLoadingError(false);
            }).catch((err) => {
                console.error(err)
                setLoadingError(true);
            })
    }
    useEffect(fetchValue, [productFilter, dateFilter])

    const validValue =  (typeof value === 'string' && value) || typeof value === 'number';

    const trendPercent = calcTrendPercent(value, pastValue);

    const trendIcon = trendPercent > 0 ? faCaretUp : faCaretDown;
    const trendColor = trendPercent > 0 ? "text-success" : "text-danger";

    // TODO: It's 2020, make the inner box here an actual flexbox.
    return (
        <InfoBox
            title={title}
            error={loadingError}
            loading={loading}
            noData={!validValue}
            isUniqueContent={isUniqueContent}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            overlay={overlay}
        >
            <div className="mt-5 pt-2">
                <div>
                    <span
                        style={{fontSize: "4rem", lineHeight: "0.9"}}
                        className="font-weight-light"
                    >
                        {value}
                    </span>
                </div>
                { trendPercent !== 0 &&
                <div className="text-primary">
                    <span className={trendColor}>
                        <FontAwesomeIcon icon={trendIcon} />
                    </span>
                    &nbsp;
                    {Math.abs(trendPercent.toFixed(0))}% <small>since {pastDate}</small>
                </div>
                }
                { text &&
                    <div
                        className="font-weight-light border-top w-100 position-absolute"
                        style={{fontSize: "1.1rem", bottom: "0.25rem", padding: "0.5rem 0"}}
                    >
                        {text}
                    </div>
                }
            </div>
        </InfoBox>
    );
}

export default SingleValueDisplay;
