import React from 'react'
import { Button, Spinner } from 'reactstrap'

const SpinnerButton = (props) => {
    const { children, disabled, processing, ...rest } = props

    return <Button
        data-testid="spinner-button"
        disabled={ (disabled || processing) }
        { ...rest }
    >
        { processing && (
            <>
                <Spinner
                    data-testid="spinner-button-busy-indicator"
                    color="secondary"
                    size="sm"
                />
                { ' ' }
            </>
        )}
        { children }
    </Button>
}

export default SpinnerButton
