import React from 'react'
import {
    Card,
    CardBody,
    CardText,
    CardTitle
} from 'reactstrap'

const Editor = (props) => {
    return <Card>
            <CardBody>
                <CardTitle data-testid="card-title">{ props.title }</CardTitle>
                <CardText data-testid="card-text">{ props.description }</CardText>
                <div className="py-2 pl-4">
                    { props.children }
                </div>
            </CardBody>
        </Card>
}

export default Editor
