import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { isLoggedIn } from '../../helpers/permissions'
import { verifyRouteRules } from '../../helpers/routes'

const PrivateRoute = ({ component: Component, rules, ...rest }) => {
    return <Route
        { ...rest }
        render={
            (props) => {
                if (isLoggedIn()) {
                    const failedRouteRuleKey =  verifyRouteRules(rules)

                    if (failedRouteRuleKey > -1) {
                        return <Redirect
                            to={{
                                pathname: rules[failedRouteRuleKey].redirect,
                                state: { from: props.location }
                            }}
                        />
                    } else {
                        return <Component { ...props } />
                    }
                } else {
                    return <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                }
            }
        }
    />
}

export default PrivateRoute