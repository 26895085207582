import {
    LANGUAGE_FETCH_FAILED,
    LANGUAGE_FETCH_IN_PROGRESS,
    LANGUAGE_FETCH_SUCCESS
} from '../../actions/language'

export const STATE_LANGUAGE_FETCH_IN_PROGRESS = 'STATE_LANGUAGE_FETCH_IN_PROGRESS'
export const STATE_LANGUAGE_FETCH_SUCCESS = 'STATE_LANGUAGE_FETCH_SUCCESS'
export const STATE_LANGUAGE_NOT_FETCHED = 'STATE_LANGUAGE_NOT_FETCHED'

const initialState = {
    error: null,
    phase: STATE_LANGUAGE_NOT_FETCHED,
    language: 'en'
}

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGE_FETCH_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                phase: STATE_LANGUAGE_NOT_FETCHED
            }
        }
        case LANGUAGE_FETCH_IN_PROGRESS: {
            return {
                ...state,
                error: null,
                phase: STATE_LANGUAGE_FETCH_IN_PROGRESS
            }
        }
        case LANGUAGE_FETCH_SUCCESS: {
            return {
                ...state,
                error: null,
                phase: STATE_LANGUAGE_FETCH_SUCCESS,
                language: action.payload.language
            }
        }
        default: {
            return state
        }
    }
}

export default languageReducer
