import React, { useState } from 'react'
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next'

import Dashboard from './Dashboard'
import Accordion from '../../components/analytics/Accordion'

import ComparisonDisplay from '../../components/analytics/ComparisonDisplay'
import LineGraphDisplay from '../../components/analytics/LineGraphDisplay'
import SingleValueDisplay from '../../components/analytics/SingleValueDisplay'
import TableDisplay from '../../components/analytics/TableDisplay'

import * as api from '../../api/analytics';

import { formatPercent, formatTime, formatDecimal } from '../../helpers/analytics';

import { ReactComponent as ThumbsUp } from '../../images/thumbs-up-selected.svg';
import { ReactComponent as ThumbsDown } from '../../images/thumbs-down-selected.svg';
import { ReactComponent as ThumbsDownGray } from '../../images/thumbs-down.svg';
import TeaserBox from '../../components/analytics/TeaserBox';


const NoopSpacer = (props) => <Col />


const MarketingView = (props) => {
    const [ productFilter, setSlugFilter ] = useState("");
    const { t } = useTranslation()

    const handleProductChange = (e) => {
        setSlugFilter(e.target.value);
    }

    const userOverlay = (
        <TeaserBox
            boxNames={[
                t("analytics.box.system-language"),
                t("analytics.box.scan-times"),
            ]}
        />
    )

    const engagementOverlay = (
        <TeaserBox
            boxNames={[
                t("analytics.box.session-count"),
                t("analytics.box.returning-rate"),
                t("analytics.box.avg-pages-viewed"),
                t("analytics.box.bounce-rate"),
                t("analytics.box.top-pages"),
                t("analytics.box.content-performance-breakdown"),
            ]}
        />
    )

    // TODO: Don't try to fetch boxes that we have no permission to fetch.

    return (
        <Dashboard setSlugFilter={handleProductChange}>
            <Row>
                <SingleValueDisplay
                    title={t("analytics.box.user-data")}
                    text={t("analytics.box.user-data-text")}
                    valueFetcher={api.getTotalUsers}
                    valueFormatter={(v) => v === undefined ? 0 : v}
                    productFilter={productFilter}
                />
                <SingleValueDisplay
                    title={t("analytics.box.engagement-data")}
                    text={t("analytics.box.engagement-data-text")}
                    valueFetcher={api.getEngagementRate}
                    valueFormatter={formatPercent(2)}
                    productFilter={productFilter}
                />
            </Row>
            <Accordion title={t("analytics.accordion.user-data")}>
                <Row>
                    <TableDisplay
                        title={t("analytics.box.scan-locations")}
                        headers={[
                            t("analytics.box.scan-locations-header1"),
                            t("analytics.box.scan-locations-header2")
                        ]}
                        itemsFetcher={api.getUsersPerCountryCity}
                        productFilter={productFilter}
                    ></TableDisplay>
                    <TableDisplay
                        title={t("analytics.box.system-language")}
                        headers={[
                            t("analytics.box.system-language-header1"),
                            t("analytics.box.system-language-header2")
                        ]}
                        itemsFetcher={api.getSessionsPerSystemLanguage}
                        productFilter={productFilter}
                        isUniqueContent={true}
                        overlay={userOverlay}
                    ></TableDisplay>
                    <LineGraphDisplay
                        title={t("analytics.box.scan-times")}
                        dataFetcher={api.getSessionsPerHour}
                        tickCallback={value => `${value}:00`}
                        productFilter={productFilter}
                        isUniqueContent={true}
                        overlay={userOverlay}
                    ></LineGraphDisplay>
                </Row>
            </Accordion>
            <Accordion title={t("analytics.accordion.engagement-data")}>
                <Row>
                    <SingleValueDisplay
                        title={t("analytics.box.time-spent")}
                        valueFetcher={api.getSessionDurationAverage}
                        valueFormatter={formatTime}
                        productFilter={productFilter}
                    />
                    <ComparisonDisplay
                        title={t("analytics.box.content-performance")}
                        valueFetcher={api.getTotalContentblockRatings}
                        valueFormatter={formatPercent(0)}
                        productFilter={productFilter}
                        headers={{left: <ThumbsUp />, right: <ThumbsDownGray />}}
                    />
                    <SingleValueDisplay
                        title={t("analytics.box.session-count")}
                        valueFetcher={api.getTotalSessions}
                        valueFormatter={(v) => v}
                        productFilter={productFilter}
                        isUniqueContent={true}
                        overlay={engagementOverlay}
                    />
                </Row>
                <Row>
                    <ComparisonDisplay
                        title={t("analytics.box.returning-rate")}
                        valueFetcher={api.getNewSessionsPercent}
                        valueFormatter={formatPercent(0)}
                        productFilter={productFilter}
                        headers={{
                            left: t("analytics.box.returning-rate-left"),
                            right: t("analytics.box.returning-rate-right")
                        }}
                        fontSize="1.2rem"
                        isUniqueContent={true}
                        overlay={engagementOverlay}
                    />
                    <SingleValueDisplay
                        title={t("analytics.box.avg-pages-viewed")}
                        valueFetcher={api.getPageviewsPerSession}
                        valueFormatter={formatDecimal}
                        productFilter={productFilter}
                        isUniqueContent={true}
                        overlay={engagementOverlay}
                    />
                    <SingleValueDisplay
                        title={t("analytics.box.bounce-rate")}
                        valueFetcher={api.getBounceRate}
                        valueFormatter={formatPercent(2)}
                        productFilter={productFilter}
                        isUniqueContent={true}
                        overlay={engagementOverlay}
                    />
                </Row>
                <Row>
                    <TableDisplay
                        title={t("analytics.box.top-pages")}
                        headers={[
                            t("analytics.box.top-pages-header1"),
                            t("analytics.box.top-pages-header2")
                        ]}
                        itemsFetcher={api.getTopPagesPerUniquePageviews}
                        productFilter={productFilter}
                        isUniqueContent={true}
                        overlay={engagementOverlay}
                    ></TableDisplay>
                    <TableDisplay
                        title={t("analytics.box.content-performance-breakdown")}
                        headers={[
                            t("analytics.box.content-performance-breakdown-header1"),
                            <ThumbsUp style={{width: "0.85rem"}}/>,
                            <ThumbsDown style={{width: "0.85rem"}}/>
                        ]}
                        itemsFetcher={api.getTotalContentblockRatingsPerBlock}
                        productFilter={productFilter}
                        valueFormatter={formatPercent(0)}
                        isUniqueContent={true}
                        overlay={engagementOverlay}
                    ></TableDisplay>
                    <NoopSpacer />
                </Row>
            </Accordion>
        </Dashboard>
    );
}

export default MarketingView;
