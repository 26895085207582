import {
    ACCOUNT_FETCH_FAILED,
    ACCOUNT_FETCH_IN_PROGRESS,
    ACCOUNT_FETCH_SUCCESS
} from '../../actions/accounts'

export const STATE_ACCOUNT_FETCH_IN_PROGRESS = 'STATE_ACCOUNT_FETCH_IN_PROGRESS'
export const STATE_ACCOUNT_FETCH_SUCCESS = 'STATE_ACCOUNT_FETCH_SUCCESS'
export const STATE_ACCOUNT_NOT_FETCHED = 'STATE_ACCOUNT_NOT_FETCHED'

const initialState = {
    error: null,
    phase: STATE_ACCOUNT_NOT_FETCHED,
    account: {}
}

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_FETCH_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                phase: STATE_ACCOUNT_NOT_FETCHED,
                account: {}
            }
        }
        case ACCOUNT_FETCH_IN_PROGRESS: {
            return {
                ...state,
                error: null,
                phase: STATE_ACCOUNT_FETCH_IN_PROGRESS
            }
        }
        case ACCOUNT_FETCH_SUCCESS: {
            return {
                ...state,
                error: null,
                phase: STATE_ACCOUNT_FETCH_SUCCESS,
                account: action.payload.account
            }
        }
        default: {
            return state
        }
    }
}

export default accountReducer
