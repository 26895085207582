import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import {
    Input,
    Nav,
    NavItem,
    Popover,
    PopoverBody
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import NavLink from '../../layout/components/NavLink'

import { getDefaultProductImage } from '../../helpers/defaults'


const DashboardHeader = (props) => {
    const { t } = useTranslation()

    const { activePath, logo, products, mainLanguage } = props;
    const maxHeight = "7rem";

    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePopover = () => setPopoverOpen(!popoverOpen);

    return (
        <div className="bg-white py-4 border-top dashboard-header">
            <Container style={{maxHeight}} className="px-0">
                <Row className="no-gutters">
                    <Col className="">
                        <img
                            className="img-fluid"
                            data-testid="banner-image"
                            alt="Brand logo" src={logo || getDefaultProductImage().src}
                            style={{maxHeight}}
                        />
                    </Col>
                    <Col className="justify-content-center align-items-center d-flex">
                        <Nav className="">
                            <NavItem>
                                <NavLink horizontal active={false}>
                                    <span
                                        id="operations-disabled"
                                        className="text-normal text-muted font-weight-light py-1"
                                        style={{fontSize: "1.2rem", cursor: "pointer"}}
                                    >
                                        { t('analytics.operations-link') }
                                    </span>
                                    <Popover
                                        trigger="hover active"
                                        target="operations-disabled"
                                        placement="bottom"
                                        isOpen={popoverOpen}
                                        toggle={togglePopover}
                                    >
                                        <PopoverBody>{ t('analytics.coming-soon') }</PopoverBody>
                                    </Popover>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activePath === 'analytics'}>
                                    <Link to="/analytics">
                                        <span
                                            className="text-normal text-primary font-weight-light py-1"
                                            style={{borderBottom: "3px solid black", fontSize: "1.2rem"}}
                                        >
                                            { t('analytics.marketing-link') }
                                        </span>
                                    </Link>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col className="justify-content-end align-items-center d-flex">
                        <Input
                            type="select" name="select"
                            style={{maxWidth: "14rem"}}
                            className="text-blue"
                            onChange={props.setSlugFilter}
                        >
                            <option key="none" value="">{ t('analytics.all-products') }</option>
                            { products.map(p =>
                            <option key={p.id} value={p.slug}>{p[`description_short_${mainLanguage}`]}</option>
                            )}
                        </Input>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DashboardHeader;
