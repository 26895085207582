import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';

import InfoBox from './InfoBox'

import { DEFAULT_DATES } from '../../api/analytics';


const WHITE = "rgba(255, 255, 255, 255)";
const BLACK = "rgba(0, 0, 0, 255)";
const BG1 = "rgba(139, 139, 139, 255)";
const BG2 = "rgba(139, 139, 139, 0)";


const LineGraphDisplay = (props) => {
    const {
        isUniqueContent,
        tickCallback,
        productFilter,
        dataFetcher,
        title,
        overlay,
    } = props;

    const [ data, setData ] = useState({dimensions: [], values: []});
    const [ loading, setLoading ] = useState(false);
    const [ loadingError, setLoadingError ] = useState(false);
    const [ dateFilter, setDateFilter ] = useState(DEFAULT_DATES);

    useEffect(() => {
        setLoading(true);
        dataFetcher(productFilter, dateFilter)
            .then((data) => {
                if (data) {
                    setData(data);
                }
                setLoading(false);
                setLoadingError(false);
            }).catch((err) => {
                console.error(err)
                setLoadingError(true);
            })
    }, [productFilter, dateFilter, dataFetcher])

    // We pass a function to `data` for accessing the canvas context.
    const setupData = (canvas) => {
        const ctx = canvas.getContext("2d");

        const gradientFill = ctx.createLinearGradient(0, 20, 0, 190);
        gradientFill.addColorStop(0, BG1);
        gradientFill.addColorStop(1, BG2);

        return {
            labels: data.dimensions,
            datasets: [
                {
                    lineTension: 0.3,
                    backgroundColor: gradientFill,
                    borderColor: BLACK,
                    borderWidth: 2.5,
                    pointBorderColor: BLACK,
                    pointBackgroundColor: BLACK,
                    pointBorderWidth: 1,
                    pointHoverRadius: 3,
                    pointHoverBackgroundColor: WHITE,
                    pointHoverBorderColor: BLACK,
                    pointHoverBorderWidth: 3,
                    pointRadius: 3,
                    pointHitRadius: 15,
                    data: data.values,
                }
            ]
        }
    }

    const legend = {
        display: false
    };

    const tickFormatting = tickCallback ? tickCallback : (v) => v;

    const step = Math.max(...data.values) / 2 + 2

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    stepSize: step
                }
            }],
            xAxes: [{
                ticks: {
                    maxTicksLimit: 6,
                    maxRotation: 0,
                    callback: tickFormatting,
                }
            }]
        }
    };

    return (
        <InfoBox
            title={title}
            error={loadingError}
            loading={loading}
            noData={!data.values.length}
            isUniqueContent={isUniqueContent}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            overlay={overlay}
        >
            <div className="px-0">
                <Line
                    data={setupData}
                    legend={legend}
                    options={options}
                    height={208}
                />
            </div>
        </InfoBox>
    );
}

export default LineGraphDisplay;
