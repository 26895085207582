import React from 'react'
import { Row } from 'reactstrap'

const List = (props) => {
    return (
        (props.children) ?
        (
            <Row data-testid="list-container">
                { props.children }
            </Row>
        ) :
        null
    )
}
export default List