import rateLimit from 'axios-rate-limit';

import { api } from './index'
import moment from 'moment';

// GA Reporting API has a burst limit of 10 / second.
// Try to play on the safe side of that.
const limitedApi = rateLimit(api, { maxRequests: 1, perMilliseconds: 105 })

export const DEFAULT_DATES = {
    startDate: moment().subtract(1, "month"),
    endDate: moment()
};

/*
 * This should come in handy if you want to combine the values & metrics
 * lists into one clean dictionary.
const mapValuesToMetrics = (data) => {
    const mappedData = {};

    data.metrics.forEach((metric, i) => {
        mappedData[metric] = data.values[i];
    });

    return mappedData;
};
*/

const mapSimpleDimensionList = (data) => {
    const mappedData = [];

    data.dimensions.forEach((dimension, i) => {
        mappedData.push({
            line: [dimension, data.values[i]]
        });
    });

    return mappedData;
};

const apiDate = (m) => {
    const API_FORMAT = "YYYY-MM-DD";
    return m.format(API_FORMAT);
};

const handleFilters = (slug, {startDate, endDate}) => {
    const filters = {
        start: apiDate(startDate),
        end: apiDate(endDate)
    };
    if (slug) {
        filters["slug"] = slug
    }
    return {params: filters}
}

export const getTotalSessions = (slug, dateFilters) => {
    return limitedApi.get('/analytics/total_sessions/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getTotalUsers = (slug, dateFilters) => {
    return limitedApi.get('/analytics/total_users/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getNewSessionsPercent = (slug, dateFilters) => {
    return limitedApi.get('/analytics/new_sessions_percent/', handleFilters(slug, dateFilters))
        .then((response) => {
            const [ newSessions ] = response.data.values;
            const returning = 100 - newSessions;

            return {left: newSessions, right: returning}
        })
}


export const getSessionDurationAverage = (slug, dateFilters) => {
    return limitedApi.get('/analytics/session_duration_average/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getBounceRate = (slug, dateFilters) => {
    return limitedApi.get('/analytics/bounce_rate/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getPageviewsPerSession = (slug, dateFilters) => {
    return limitedApi.get('/analytics/pageviews_per_session/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getEngagementRate = (slug, dateFilters) => {
    return limitedApi.get('/analytics/engagement_rate/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getSessionsPerHour = (slug, dateFilters) => {
    return limitedApi.get('/analytics/sessions_per_hourbracket/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getSessionsPerSystemLanguage = (slug, dateFilters) => {
    return limitedApi.get('/analytics/sessions_per_system_language/', handleFilters(slug, dateFilters))
        .then((response) => {
            return mapSimpleDimensionList(response.data)
        })
}


export const getTopPagesPerUniquePageviews = (slug, dateFilters) => {
    return limitedApi.get('/analytics/top_pages_by_unique_pageviews/', handleFilters(slug, dateFilters))
        .then((response) => {
            return mapSimpleDimensionList(response.data)
        })
}


export const getTotalContentblockRatings = (slug, dateFilters) => {
    return limitedApi.get('/analytics/total_contentblock_ratings/', handleFilters(slug, dateFilters))
        .then((response) => {

            const [ {pos, neg} ] = response.data.values;
            return {left: pos, right: neg}
        })
}


export const getTotalContentblockRatingsPerBlock = (slug, dateFilters) => {
    return limitedApi.get('/analytics/total_contentblock_ratings_per_block/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getUsersPerAge = (slug, dateFilters) => {
    return limitedApi.get('/analytics/users_per_agebucket/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}


export const getUsersPerCountryCity = (slug, dateFilters) => {
    return limitedApi.get('/analytics/users_per_countrycity/', handleFilters(slug, dateFilters))
        .then((response) => {
            return mapSimpleDimensionList(response.data)
        })
}


export const getUsersPerGender = (slug, dateFilters) => {
    return limitedApi.get('/analytics/users_per_gender/', handleFilters(slug, dateFilters))
        .then((response) => {
            return response.data
        })
}
