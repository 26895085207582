import { api } from './index'

export const getById = (id) => {
    return api.get('/accounts/' + id + '/')
        .then((response) => {
            return response.data
        })
}

export const update = (id, data) => {
    return api.patch(
        '/accounts/' + id + '/',
        data
    )
        .then((response) => {
            return response.data
        })
}
