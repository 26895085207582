import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, FormGroup } from 'reactstrap'

import SpinnerButton from '../SpinnerButton'

const Controls = (props) => {
    const { t } = useTranslation()

    return <FormGroup className="mt-5" data-testid="form-controls">
        { (props.errorMessage !== false) && (
            <div key="alert">
                <Alert data-testid="form-controls-alert" color="danger">
                    { t(props.errorMessageTranslationKey) }
                </Alert>
            </div>
        )}
        <Button
            className="button-row-button"
            color="secondary"
            onClick={ props.onCancel }
        >
            { t('common.cancel') }
        </Button>
        <SpinnerButton
            className="button-row-button"
            color="primary"
            disabled={ (!props.isSaveable()) }
            processing={ props.processing }
            onClick={ props.onSave }
        >
            { t('common.save') }
        </SpinnerButton>
    </FormGroup>
}

export default Controls
