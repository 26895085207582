import React from 'react'

import Navigation from './components/Navigation'

const Layout = (props) => {
    const { contentHeader } = props
    const showNavigation = !props.hideNavigation

    return <div>
        { showNavigation &&
            <div className="header-wrapper">
                <Navigation />
            </div>
        }
        { contentHeader &&
            <div>
                {contentHeader}
            </div>
        }
        <div className="content-wrapper">
            <div className="content">
                {props.children}
            </div>
        </div>
    </div>
}

export default Layout
