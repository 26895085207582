import * as accountsApi from '../api/accounts'
import * as usersApi from '../api/users'

export const ACCOUNT_FETCH_FAILED = 'account/ACCOUNT_FETCH_FAILED'
export const ACCOUNT_FETCH_IN_PROGRESS = 'account/ACCOUNT_FETCH_IN_PROGRESS'
export const ACCOUNT_FETCH_SUCCESS = 'account/ACCOUNT_FETCH_SUCCESS'

export const ACCOUNT_USERS_FETCH_FAILED = 'accountUsers/ACCOUNT_USERS_FETCH_FAILED'
export const ACCOUNT_USERS_FETCH_IN_PROGRESS = 'accountUsers/ACCOUNT_USERS_FETCH_IN_PROGRESS'
export const ACCOUNT_USERS_FETCH_SUCCESS = 'accountUsers/ACCOUNT_USERS_FETCH_SUCCESS'

/**
 * Fetch account action.
 * 
 * @param {Number} id - Account id
 * @return {Object}
 */
export const fetchAccount = (id) => {
    return (dispatch) => {
        dispatch(startAccountFetching())
        return accountsApi.getById(id)
            .then((data) => {
                dispatch(accountFetchSuccess(data))
            }).catch((error) => {
                dispatch(accountFetchFail(error))
            })
    }
}

/**
 * Fetch account users action.
 * 
 * @return {Object}
 */
export const fetchAccountUsers = () => {
    return (dispatch) => {
        dispatch(startAccountUserFetching())
        return usersApi.get()
            .then((data) => {
                dispatch(accountUserFetchSuccess(data))
            }).catch((error) => {
                dispatch(accountUserFetchFail(error))
            })
    }
}

/**
 * Account fetch fail action.
 * 
 * @param {Object} error - Error object
 * @return {Object}
 */
const accountFetchFail = (error) => ({
    type: ACCOUNT_FETCH_FAILED,
    payload: {
        error
    }
})

/**
 * Account fetch success action.
 * 
 * @return {Object}
 */
const accountFetchSuccess = (account) => ({
    type: ACCOUNT_FETCH_SUCCESS,
    payload: {
        account
    }
})

/**
 * Acceount user fetch fail action.
 * 
 * @param {Object} error - Error object
 * @return {Object}
 */
const accountUserFetchFail = (error) => ({
    type: ACCOUNT_USERS_FETCH_FAILED,
    payload: {
        error
    }
})

/**
 * Account user fetch success action.
 * 
 * @return {Object}
 */
const accountUserFetchSuccess = (users) => ({
    type: ACCOUNT_USERS_FETCH_SUCCESS,
    payload: {
        users
    }
})

/**
 * Start account fetching action.
 * 
 * @return {Object}
 */
const startAccountFetching = () => ({
    type: ACCOUNT_FETCH_IN_PROGRESS
})

/**
 * Start account user fetching action.
 * 
 * @return {Object}
 */
const startAccountUserFetching = () => ({
    type: ACCOUNT_USERS_FETCH_IN_PROGRESS
})
