import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { fetchAccountUsers } from '../../actions/accounts'

import { deleteUser } from '../../api/users'

import Card from '../../components/Card'
import ConfirmationModal from '../../components/ConfirmationModal'
import List from '../../components/List'

import Layout from '../../layout/Layout'

const UserListView = (props) => {
    const [ deleteConfirmationModalId, setDeleteConfirmationModalId ] = useState(false)
    const { t } = useTranslation()
    const accountUsers = useSelector(state => state.accountUsers)
    const dispatch = useDispatch()

    const onCancelDelete = () => {
        setDeleteConfirmationModalId(false)
    }

    const onConfirmDelete = () => {
        deleteUser(accountUsers.users[deleteConfirmationModalId].id)
            .then((_) => {
                dispatch(fetchAccountUsers())
            }).catch((_) => {
                // TODO: ERROR HANDLING
            }).finally(() => {
                setDeleteConfirmationModalId(false)
            })
    }

    const onEditClick = (userId) => {
        props.history.push('/account/users/' + encodeURIComponent(userId))
    }

    const onDeleteClick = (userId) => {
        setDeleteConfirmationModalId(userId)
    }

    return <Layout>
        <ConfirmationModal
            display={ (deleteConfirmationModalId !== false) }
            body={
                t(
                    'account.users.delete-confirmation.text',
                    (
                        (typeof accountUsers.users[deleteConfirmationModalId] !== 'undefined') ?
                        {
                            firstName: accountUsers.users[deleteConfirmationModalId].first_name,
                            lastName: accountUsers.users[deleteConfirmationModalId].last_name
                        } :
                        {}
                    )
                )
            }
            cancelText={ t('common.cancel') }
            confirmText={ t('common.confirm') }
            header={ t('account.users.delete-confirmation.header') }
            onCancel={ () => onCancelDelete() }
            onConfirm={ () => onConfirmDelete() }
        />
        <List>
            { accountUsers.users.map((user, id) => <Card
                key={ id }
                contextMenuId={ id }
                contextMenuControls={[
                    <Button
                        key="delete"
                        color="danger"
                        onClick={ () => onDeleteClick(id) }
                    >
                        { t('card.controls.delete') }
                    </Button>
                ]}
                controls={[
                    <Button
                        key="edit"
                        color="secondary"
                        onClick={ () => onEditClick(user.id) }
                    >
                        { t('card.controls.edit') }
                    </Button>
                ]}
                description={(
                    <>
                        { user.email }
                    </>
                )}
                title={ user.first_name + ' ' + user.last_name }
            />) }
            <Card
                centerControls
                controls={[
                    <Button
                        key="edit"
                        color="primary"
                        onClick={ () => onEditClick('new') }
                    >
                        { t('account.users.add') }
                    </Button>
                ]}
                title="&nbsp;"
            />
        </List>
    </Layout>
}

export default UserListView
