import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Badge, Button, Col, Row } from 'reactstrap'
import { BiCaretDown, BiCaretUp, BiPlus } from 'react-icons/bi'
import uniqueString from 'unique-string'

import BasicForm from '../BasicForm'
import ContextMenuButton from '../ContextMenuButton'
import Group from './Group'

import {
    combineTranslationFields,
    getMandatoryFieldNames,
    isMandatoryFieldsFilled
} from '../../helpers/editor'
import { combineTranslationFieldKeyValuePairs } from '../../helpers/language'
import { addValues } from '../../helpers/objects'

const ContentBlockEditor = (props) => {
    const [ contextMenuOpen, setContextMenuOpen ] = useState(false);
    const { t } = useTranslation()

    const getHeaderTranslationKey = (key) => {
        switch (key) {
            case 'link': {
                return 'products.content-blocks.link'
            }
            case 'text': {
                return 'products.content-blocks.text'
            }
            case 'video': {
                return 'products.content-blocks.embedded-video'
            }
            default:
                return ''
        }
    }

    const getHeaderWithMandatoryIndicator = (item) => {
        const mandatoryFields = getMandatoryFieldNames(
            'products.content-blocks.' + item.content_type
        )

        return <>
            <div className="float-right mr-3">
                <Button
                    color="secondary"
                    size="sm"
                    onClick={ (e) => onMove(e, item.arrayIndex, 'up') }
                >
                    <BiCaretUp />
                </Button>
                <Button
                    color="secondary"
                    size="sm"
                    onClick={ (e) => onMove(e, item.arrayIndex, 'down') }
                >
                    <BiCaretDown />
                </Button>
                &nbsp;
            </div>
            <div className="float-right">
                <Button
                    color={ (
                        ('delete' in item && item.delete) ?
                        'info' :
                        'danger'
                    ) }
                    size="sm"
                    onClick={ (e) => onToggleDelete(e, item.arrayIndex) }
                >
                    { t(
                        'common.' + (
                            ('delete' in item && item.delete) ?
                            'revert' :
                            (
                                (!isNaN(parseInt(item.id))) ?
                                'mark-as-deleted' :
                                'delete'
                            )
                        )
                    ) }
                </Button>
                &nbsp;
            </div>
            { t(getHeaderTranslationKey(item.content_type)) }
            { ' ' }
            { (
                mandatoryFields.length > 0 &&
                !isMandatoryFieldsFilled(mandatoryFields, item)
            ) &&
            (
                <>
                    { ' ' }
                    <Badge color="danger" pill>
                        &nbsp;
                    </Badge>
                </>
            )}
        </>
    }

    const getTranslationFields = (item) => {
        const { processing, selectedLanguages } = props

        const mandatoryFieldsKey = 'products.content-blocks.' + item.content_type
        let fields = []

        if (['link', 'video'].includes(item.content_type)) {
            fields = fields.concat(
                combineTranslationFields(
                    item,
                    processing,
                    selectedLanguages,
                    'products.content-blocks.fields.' + item.content_type + '-content-url',
                    'content_url',
                    'text',
                    {},
                    mandatoryFieldsKey
                )
            )
        }

        fields = fields.concat(
            combineTranslationFields(
                item,
                processing,
                selectedLanguages,
                'products.content-blocks.fields.' + item.content_type + '-content-headline',
                'content_headline',
                'text',
                {},
                mandatoryFieldsKey
            )
        )

        if (['text', 'link'].includes(item.content_type)) {
            fields = fields.concat(
                combineTranslationFields(
                    item,
                    processing,
                    selectedLanguages,
                    'products.content-blocks.fields.' + item.content_type + '-content-image',
                    'content_banner',
                    'file',
                    {},
                    mandatoryFieldsKey
                )
            )
        }

        fields = fields.concat(
            combineTranslationFields(
                item,
                processing,
                selectedLanguages,
                'products.content-blocks.fields.' + item.content_type + '-content-text',
                'content_text',
                'textarea',
                {},
                mandatoryFieldsKey
            )
        )

        return fields
    }

    const onAddBlock = (type) => {
        setContextMenuOpen(false)

        const itemWithHighestOrder = (
            (props.data.length > 0) ?
            props.data.reduce((previous, current) => {
                return ((current.order > previous.order) ? current : previous)
            }) :
            null
        )

        const newBlock = {
            id: 'temp-' + uniqueString(),
            position: props.position,
            content_type: type,
            order: (
                (itemWithHighestOrder) ?
                (itemWithHighestOrder.order + 1) :
                1
            )
        }

        addValues(
            newBlock,
            combineTranslationFieldKeyValuePairs(
                'content_url',
                ((type === 'link') ? null : '')
            )
                .concat(
                    combineTranslationFieldKeyValuePairs('content_headline', ''),
                    combineTranslationFieldKeyValuePairs('content_text', ''),
                    combineTranslationFieldKeyValuePairs(
                        'content_banner',
                        ((type === 'video') ? null : '')
                    )
                )
        )

        props.onAddBlock(newBlock)
    }

    const onMove = (e, arrayIndex, direction) => {
        e.stopPropagation()

        if (['down', 'up'].includes(direction)) {
            const selectedItem = props.data[arrayIndex]
            
            let itemToSwapOrderValueWith = null
            let itemToSwapOrderValueWithId = null
            if (props.data.length > 1) {
                props.data.forEach((item, id) => {
                    if (
                        (
                            (
                                direction === 'down' &&
                                item.order > selectedItem.order
                            ) ||
                            (
                                direction === 'up' &&
                                item.order < selectedItem.order
                            )
                        ) &&
                        (
                            itemToSwapOrderValueWith === null ||
                            Math.abs(
                                (item.order - selectedItem.order)
                            ) < Math.abs(
                                (itemToSwapOrderValueWith.order - selectedItem.order)
                            )
                        )
                    ) {
                        itemToSwapOrderValueWith = item
                        itemToSwapOrderValueWithId = id
                    }
                })
            }

            if (itemToSwapOrderValueWithId !== null) {
                props.onBlockSwapOrderNumbers(
                    arrayIndex,
                    itemToSwapOrderValueWithId
                )
            }
        }
    }

    const onToggleDelete = (e, arrayIndex) => {
        e.stopPropagation()

        const item = props.data[arrayIndex]
        if (!isNaN(parseInt(item.id))) {
            // Soft delete
            if ('delete' in item && item.delete) {
                props.onBlockDelete(arrayIndex, true)
            } else {
                props.onFieldChange(
                    [arrayIndex, 'delete'],
                    true
                )
            }
        } else {
            // Hard delete
            props.onBlockDelete(arrayIndex)
        }
    }

    const onToggleContextMenu = () => {
        setContextMenuOpen(!contextMenuOpen)
    }

    return <div>    
        <Row className="context-menu-button-container">
            <ContextMenuButton
                contextMenuButtonId="context-block-editor-button"
                contextMenuControls={[
                    <Button
                        key="embedded-video"
                        block
                        color="secondary"
                        onClick={ () => onAddBlock('video') }
                    >
                        { t('products.content-blocks.embedded-video') }
                    </Button>,
                    <Button
                        key="text"
                        block
                        color="secondary"
                        onClick={ () => onAddBlock('text') }
                    >
                        { t('products.content-blocks.text') }
                    </Button>,
                    <Button
                        key="link"
                        block
                        color="secondary"
                        onClick={ () => onAddBlock('link') }
                    >
                        { t('products.content-blocks.link') }
                    </Button>
                ]}
                contextMenuPlacement="top"
                contextMenuTrigger="legacy"
                isOpen={ contextMenuOpen }
                onToggle={ () => onToggleContextMenu() }
            >
                <BiPlus />
            </ContextMenuButton>
            <span>
                { t('products.add-content-blocks') }
            </span>
        </Row>
        { (props.errorMessage !== false) && (
            <div key="alert">
                <Alert data-testid="content-block-editor-alert" color="danger">
                    { t(props.errorMessageTranslationKey) }
                    { (props.errorMessage.length > 0) && (
                        <ul>
                            { props.errorMessage.map((item, id) => (
                                <li key={ id }>{ item[Object.keys(item)[0]] }</li>
                            ))}
                        </ul>
                    )}
                </Alert>
            </div>
        )}
        <Row>
            <Col sm={ 12 }>
                <Group
                    groups={ props.data.map((item, id) => {
                        const newItem = { ...item }
                        newItem.arrayIndex = id

                        return newItem
                    }).filter((item) => item.position === props.position)
                    .sort((a, b) => {
                        return (a.order - b.order)
                    }).map((item) => {
                        return {
                            header: getHeaderWithMandatoryIndicator(item),
                            content: <BasicForm
                                fields={ getTranslationFields(item) }
                                formId={ item.arrayIndex }
                                onFieldChange={ props.onFieldChange }
                            />,
                            visibilityId: item.id
                        }
                    }) }
                />
            </Col>
        </Row>
    </div>
}

export default ContentBlockEditor
