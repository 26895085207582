import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Badge,
    Button,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap'
import moment from 'moment'

import { isValidUrl } from '../helpers/url'

const BasicFrom = (props) => {
    const { t } = useTranslation()

    const onSubmit = (e) => {
        e.preventDefault()

        if (props.onSubmit) {
            props.onSubmit(e)
        }
    }

    const controlsClass = props.centerControls ? "text-center" : ""

    return <Form
        data-testid="form"
        className={ props.className }
        onSubmit={ (e) => onSubmit(e) }
    >
        { props.fields.map((field, id) => {
            if (field.visible === false) {
                return null
            } else {
                const fieldKey = (
                    ('formId' in props) ?
                    [
                        props.formId,
                        field.name
                    ] :
                    field.name
                )

                const isFileField = (field.type === 'file')
                const isInline = (field.inline === true)

                const isDateTimeField = (field.type === 'datetime-local')
                let dateTimeValue = ''
                if (isDateTimeField) {
                    const value = moment(field.value)

                    if (value.isValid()) {
                        dateTimeValue = moment(field.value)
                            .format('YYYY-MM-DDThh:mm')
                    }
                }

                return <FormGroup key={ id } row={ isInline }>
                    {
                        field.label && (
                            <Label
                                className={ ((isInline) ? 'inline-label' : null) }
                                data-testid={ 'form-label-' + field.name }
                                sm={ ((isInline) ? 2 : null) }
                            >
                                { field.label }
                                { (field.mandatory && !field.value) && (
                                    <>
                                        { ' ' }
                                        <Badge color="danger" pill>
                                            &nbsp;
                                        </Badge>
                                    </>
                                )}
                            </Label>
                        )
                    }
                    {
                        (['checkbox', 'radio'].includes(field.type)) ?
                        (
                            (typeof field.value === 'object') ?
                            (
                                <div>
                                    { Object.entries(field.value)
                                        .map((subField, subFieldId) => {
                                            return <CustomInput
                                                key={ subFieldId }
                                                id={ subFieldId }
                                                type={ field.type }
                                                data-testid={ 'form-custom-input-' + field.name + '-' + subField[0] }
                                                disabled={ field.disabled }
                                                name={ field.name + '-' + subField[0] }
                                                label={ field.subLabels[subFieldId] }
                                                checked={ subField[1] }
                                                className={ field.className }
                                                onChange={
                                                    (e) => props.onFieldChange(
                                                        [fieldKey, subField[0]],
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                    ) }
                                </div>
                            ) :
                            (
                                <CustomInput
                                    id={ 'form-input-' + field.name }
                                    type={ field.type }
                                    data-testid={ 'form-input-' + field.name }
                                    disabled={ field.disabled }
                                    name={ field.name }
                                    checked={ field.value }
                                    className={ field.className }
                                    onChange={
                                        (e) => props.onFieldChange(
                                            fieldKey,
                                            e.target.checked
                                        )
                                    }
                                />
                            )
                        ) :
                        (
                            <div className={ ((isInline) ? 'col-sm-10' : null) }>
                                <Input
                                    type={ field.type }
                                    data-testid={ 'form-input-' + field.name }
                                    className={ field.className }
                                    disabled={ field.disabled }
                                    invalid={ !!field.errorMessage }
                                    name={ field.name }
                                    value={ (
                                        (isFileField) ?
                                        undefined :
                                        (
                                            (isDateTimeField) ?
                                            dateTimeValue :
                                            field.value
                                        )
                                    ) }
                                    onChange={
                                        (e) => props.onFieldChange(
                                            fieldKey,
                                            (
                                                (isFileField) ?
                                                e.target.files[0] :
                                                e.target.value
                                            ),
                                            isFileField
                                        )
                                    }
                                >
                                    { (
                                        (['select'].includes(field.type) && 'values' in field) ?
                                        (
                                            <>
                                                { Object.entries(field.values).map((value, id) => {
                                                    return <option
                                                        key={ id }
                                                        value={ value[0] }
                                                    >
                                                        { value[1] }
                                                    </option>
                                                }) }
                                            </>
                                        ) :
                                        null
                                    ) }
                                </Input>
                                { (isFileField && field.value) && (
                                    <p>
                                        { t('common.selected-file') }:
                                        { ' ' }
                                        <a
                                            href={ (isValidUrl(field.value) ? field.value : undefined) }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            { field.value }
                                        </a>
                                        { (field.nullable) && (
                                            <>
                                                { ' ' }
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    onClick={
                                                        () => props.onFieldChange(
                                                            fieldKey,
                                                            false,
                                                            isFileField
                                                        )
                                                    }
                                                >
                                                    { t('common.delete') }
                                                </Button>
                                            </>
                                        )}
                                    </p>
                                )}
                                { (field.errorMessage) && (
                                    <FormFeedback>
                                        { field.errorMessage }
                                    </FormFeedback>
                                )}
                            </div>
                        )
                    }
                    </FormGroup>
            }
        }) }
        {
            (
                typeof props.controls !== 'undefined' &&
                Array.isArray(props.controls)
            ) && (
                <FormGroup data-testid="form-controls" className={ controlsClass }>
                    { props.controls }
                </FormGroup>
            )
        }
    </Form>
}
export default BasicFrom
