import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Table, Collapse } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import InfoBox from './InfoBox'
import { DEFAULT_DATES } from '../../api/analytics';


const TableItem = (props) => {
    // Instead of a simple "Row" we have an "Item", because each main row
    // may have collapsible children.
    const { item, valueFormatter } = props;

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const itemClasses = (i) => classNames(
        // Twiddling to make the paddings & borders play nice when collapsing.
        {"border-0 p-0": !isOpen},

        // Indentation for first column of children.
        {"pl-4": i === 0},
        "border-0"
    );

    const hasChildren = item.children && item.children.length;

    const parentItemStyle = hasChildren ? {cursor: "pointer"} : {};

    const caret = isOpen ? faCaretDown : faCaretRight;
    const showCaret = (i) => {
        return hasChildren && i === 0;
    }

    const formatValue = (value, i) => {
        return i !== 0 && valueFormatter
            ? valueFormatter(value)
            : value;
    }

    return (<>
        <tr onClick={hasChildren && toggle} style={parentItemStyle}>
            {item.line.map((value, col) => (
                <td key={col}>
                    {showCaret(col) &&
                        <FontAwesomeIcon
                            className="text-primary"
                            style={{width: "10px"}}
                            icon={caret}
                        />
                    }
                    &nbsp; {value}
                </td>
            ))}
        </tr>
        {hasChildren && item.children.map((child, row) =>
            <tr key={row}>
                {child.map((value, col) => (
                    <td key={col} className={itemClasses(col)}>
                        <Collapse isOpen={isOpen}>
                            {formatValue(value, col)}
                        </Collapse>
                    </td>
                ))}
            </tr>
        )}
    </>)
};


const TableDisplay = (props) => {
    const {
        headers,
        isUniqueContent,
        itemsFetcher,
        productFilter,
        title,
        valueFormatter,
        overlay,
    } = props;

    const [ items, setItems ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ loadingError, setLoadingError ] = useState(false);
    const [ dateFilter, setDateFilter ] = useState(DEFAULT_DATES);

    const fetchItems = () => {
        setLoading(true);
        setLoadingError(false);
        itemsFetcher(productFilter, dateFilter)
            .then((data) => {
                if (data) {
                    setItems(data);
                }
                setLoading(false);
                setLoadingError(false);
            }).catch((err) => {
                console.error(err)
                setLoadingError(true);
            })
    }
    useEffect(fetchItems, [productFilter, dateFilter])

    // Minimize jumping around when opening/closing children
    const headerSizePerCount = ["100%", "100%", "80%", "70%"];
    const headerWidth = (col) => col === 0 && headerSizePerCount[headers.length];

    const hasItems = items && items.length;

    return (
        <InfoBox
            title={title}
            error={loadingError}
            loading={loading}
            noData={!hasItems}
            isUniqueContent={isUniqueContent}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            overlay={overlay}
        >
            <div className="text-left" style={{fontSize: "0.8rem", height:"100%"}}>
                <Table size="sm">
                    <thead>
                        <tr>
                            {headers.map((header, col) => (
                                <th
                                    className="text-dark border-0 font-weight-light"
                                    key={col}
                                    style={{width: headerWidth(col)}}
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, row) =>
                            <TableItem
                                key={row}
                                item={item}
                                valueFormatter={valueFormatter}
                            />
                        )}
                    </tbody>
                </Table>
            </div>
        </InfoBox>
    );
};

export default TableDisplay;

