import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAccount, fetchAccountUsers } from './actions/accounts'
import { fetchChoices } from './actions/choices'
import { fetchLanguage } from './actions/language'
import { tryLogin } from './actions/login'

import PrivateRoute from './components/router/PrivateRoute'

import { isAccountAdmin, permissions } from './helpers/permissions'

import Layout from './layout/Layout'

import { STATE_LOGIN_SUCCESS, STATE_TRY_LOGIN } from './store/user/user'

import AccountView from './views/Account/AccountView'
import AccountEditorView from './views/Account/AccountEditorView'
import PlanEditorView from './views/Account/PlanEditorView'
import UserEditorView from './views/Account/UserEditorView'
import UserListView from './views/Account/UserListView'

import MarketingView from './views/Analytics/MarketingView'

import BrandEditorView from './views/Brand/BrandEditorView'

import LoginView from './views/Login/LoginView'

import ProductCategoriesView from './views/Product/ProductCategoriesView'
import ProductListView from './views/Product/ProductListView'

const Routes = () => {
    const { t } = useTranslation()
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const NotFound = () => <div>404 - { t('not-found.header') }</div>
    const LandingPage = (props) => <Layout>
        <span><strong>EXAKT</strong> analytics, coming soon!</span>
        { Object.keys(props.match.params).length > 0 && (
            <span>Params: { JSON.stringify(props.match.params) }</span>
        ) }
    </Layout>

    useEffect(() => {
        if (user.phase === STATE_TRY_LOGIN) {
            dispatch(tryLogin())
        } else if (user.phase === STATE_LOGIN_SUCCESS) {
            dispatch(fetchLanguage())
            dispatch(fetchAccount(user.user.account.id))
            dispatch(fetchChoices())

            if (isAccountAdmin()) {
                dispatch(fetchAccountUsers())
            }
        }
    }, [dispatch, user.phase, user.user])

    return <Switch>
        <Route exact path="/">
            <Redirect to={(
                (user.phase === STATE_LOGIN_SUCCESS) ?
                '/analytics' :
                '/login'
            )} />
        </Route>
        <Route
            path="/account"
            render={
                ({ match: { url } }) => {
                    return <Switch>
                        <PrivateRoute
                            path={ url }
                            exact
                            component={ AccountView }
                        />
                        <PrivateRoute
                            path={ url + '/edit' }
                            exact
                            component={ AccountEditorView }
                            rules={[
                                {
                                    admin: true,
                                    redirect: '/account'
                                }
                            ]}
                        />
                        <PrivateRoute
                            path={ url + '/plan' }
                            exact
                            component={ PlanEditorView }
                            rules={[
                                {
                                    admin: true,
                                    redirect: '/account'
                                }
                            ]}
                        />
                        <PrivateRoute
                            path={ url + '/users' }
                            exact
                            component={ UserListView }
                            rules={[
                                {
                                    admin: true,
                                    redirect: '/account'
                                }
                            ]}
                        />
                        <PrivateRoute
                            path={ url + '/users/:userid' }
                            component={ UserEditorView }
                            rules={[
                                {
                                    admin: true,
                                    redirect: '/account'
                                }
                            ]}
                        />
                        <PrivateRoute path="*" component={ NotFound } />
                    </Switch>
                }
            }
        />
        <PrivateRoute path="/analytics" exact component={ MarketingView } />
        <PrivateRoute
            path="/brand"
            exact
            component={ BrandEditorView }
            rules={[
                {
                    permission: permissions.editBrand,
                    redirect: '/'
                }
            ]}
        />
        <Route path="/login" exact component={ LoginView } />
        <Route
            path="/products"
            render={
                ({ match: { url } }) => {
                    return <Switch>
                        <PrivateRoute
                            path={ url }
                            exact
                            component={ ProductListView }
                        />
                        <PrivateRoute
                            path={ url + '/:productid/:category' }
                            component={ LandingPage }
                        />
                        <PrivateRoute
                            path={ url + '/:productid' }
                            component={ ProductCategoriesView }
                        />
                        <PrivateRoute path="*" component={ NotFound } />
                    </Switch>
                }
            }
        />
        <Route path="*" component={ NotFound } />
    </Switch>
}

export default Routes
