import React, { useState } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { BiChevronDown, BiChevronRight } from "react-icons/bi"

const Group = (props) => {
    const [ visibleGroups, setVisibleGroups ] = useState([])

    const isVisibleGroup = (id) => {
        return visibleGroups.includes(id)
    }

    const toggleVisibility = (id) => {
        const newVisibleGroups = [ ...visibleGroups ]
        const groupKey = newVisibleGroups.indexOf(id)

        if (groupKey > -1) {
            newVisibleGroups.splice(groupKey, 1)
        } else {
            newVisibleGroups.push(id)
        }

        setVisibleGroups(newVisibleGroups)
    }

    return <ListGroup data-testid="group-wrapper">
        { props.groups.map((group, id) => {
            const visibilityId = (
                (group.visibilityId) ?
                group.visibilityId :
                id
            )
            const visible = isVisibleGroup(visibilityId)
            const Chevron = ((visible) ? BiChevronDown : BiChevronRight)

            return (
                <React.Fragment key={ id }>
                    <ListGroupItem
                        data-testid={ 'group-item-' + id }
                        onClick={ () => toggleVisibility(visibilityId) }
                    >
                        <div className="float-right">
                            <Chevron />
                        </div>
                        { group.header }
                    </ListGroupItem>
                    { (visible) && (
                        <div className="list-item-content">
                            { group.content }
                        </div>
                    )}
                </React.Fragment>
            )
        }) }
    </ListGroup>
}
export default Group
