import React from 'react'

import Editor from './components/Editor'
import Layout from './Layout'

const EditorLayout = (props) => {
    const { ...rest } = props

    return <Layout>
        <Editor { ...rest } >
            { props.children }
        </Editor>
    </Layout>
}

export default EditorLayout