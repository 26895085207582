import store from '../store/store'
import { STATE_LOGIN_SUCCESS, STATE_TRY_LOGIN } from '../store/user/user'

export const permissions = {
    addProducts: 'can_add_products',
    archiveProducts: 'can_archive_products',
    editBrand: 'can_edit_brand',
    viewAnalytics: 'can_view_analytics'
}

/**
 * Returns user data object from state.
 * 
 * @return {Object} - User data object
 */
const getUserData = () => {
    return store.getState().user
}

/**
 * Check if user has a specific permission permission.
 * 
 * @param {string} permission - Name of the permission
 * @return {boolean} - Edit mode permission
 */
export const hasPermission = (permission) => {
    const user = getUserData().user

    return (
        isAccountAdmin() ||
        (user !== null && user[permission])
    )
}

/**
 * Check if user is account admin.
 * 
 * @return {boolean} - Account admin value
 */
export const isAccountAdmin = () => {
    const user = getUserData().user

    return (user !== null && user.is_account_admin)
}

/**
 * Check if user is logged in.
 * 
 * @return {boolean} - Logged in status
 */
export const isLoggedIn = () => {
    return (
        [STATE_LOGIN_SUCCESS, STATE_TRY_LOGIN].includes(getUserData().phase)
    )
}

/**
 * Check if user is trying to log in
 * 
 * @return {boolean} - Is trying log in status
 */
export const isTryingLogIn = () => {
    return (getUserData().phase === STATE_TRY_LOGIN)
}
