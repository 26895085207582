import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import Card from '../../components/Card'
import List from '../../components/List'

import { isAccountAdmin } from '../../helpers/permissions'

import Layout from '../../layout/Layout'

const UserListView = (props) => {
    const { t } = useTranslation()
    const account = useSelector(state => state.account.account)

    const onEditClick = (path) => {
        props.history.push(path)
    }

    return <Layout>
        <List>
            <Card
                controls={[
                    (
                        (isAccountAdmin()) ?
                        (
                            <Button
                                key="edit"
                                color="primary"
                                onClick={ () => onEditClick('/account/edit') }
                            >
                                { t('account.info.edit') }
                            </Button>
                        ) :
                        null
                    )
                ]}
                description={(
                    <>
                        { t('account.info.company-name') }: { account.company_name }<br />
                        { t('account.info.address') }: { account.address }<br />
                        { t('account.info.phone') }: { account.phone }<br />
                    </>
                )}
                sm={ 12 }
                title={ t('account.info.header') }
            />
            { isAccountAdmin() && (
                <Card
                    controls={[
                        <Button
                            key="edit"
                            color="primary"
                            onClick={ () => onEditClick('/account/users') }
                        >
                            { t('account.users.edit-plural') }
                        </Button>
                    ]}
                    description="Users info..."
                    sm={ 12 }
                    title={ t('account.users.header') }
                />
            ) }
            <Card
                controls={[
                    (
                        (isAccountAdmin()) ?
                        (
                            <Button
                                key="edit"
                                color="primary"
                                onClick={ () => onEditClick('/account/plan') }
                            >
                                { t('account.plan.edit') }
                            </Button>
                        ) :
                        null
                    )
                ]}
                description={ account.subscription_type }
                sm={ 12 }
                title={ t('account.plan.header') }
            />
        </List>
    </Layout>
}

export default UserListView