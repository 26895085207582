import * as api from '../api/authentication'

export const LOGIN_REQUIRED = 'login/LOGIN_REQUIRED'
export const LOGIN_IN_PROGRESS = 'login/LOGIN_IN_PROGRESS'
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS'
export const LOGOUT = 'login/LOGOUT'

/**
 * Login action.
 * 
 * @param {Object} data - Credentials
 * @return {Object}
 */
export const login = (data) => {
    return (dispatch) => {
        dispatch(startLogin())
        return api.authenticate(data)
            .then((data) => {
                dispatch(loginSuccess(data))
            }).catch((error) => {
                dispatch(loginFail(error))
            })
    }
}

/**
 * Login fail action.
 * 
 * @param {Object} error - Error object
 * @return {Object}
 */
const loginFail = (error) => ({
    type: LOGIN_REQUIRED,
    payload: {
        error
    }
})

/**
 * Login success action.
 * 
 * @param {Object} data - User data
 * @return {Object}
 */
const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    payload: {
        user: data.user
    }
})

/**
 * Logout action.
 * 
 * @return {Object}
 */
export const logout = () => {
    api.logout()

    return {
        type: LOGOUT
    }
}

/**
 * Start login action.
 * 
 * @return {Object}
 */
const startLogin = () => ({
    type: LOGIN_IN_PROGRESS
})

/**
 * Try login action.
 * 
 * @return {Object}
 */
export const tryLogin = () => {
    return (dispatch) => {
        return api.refreshToken()
            .then((data) => {
                dispatch(loginSuccess(data))
            }).catch((error) => {
                dispatch(logout())
            })
    }
}
