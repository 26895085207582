import * as api from '../api/products'

export const PRODUCT_LIST_FETCH_FAILED = 'products/PRODUCT_LIST_FETCH_FAILED'
export const PRODUCT_LIST_FETCH_IN_PROGRESS = 'products/PRODUCT_LIST_FETCH_IN_PROGRESS'
export const PRODUCT_LIST_FETCH_SUCCESS = 'products/PRODUCT_LIST_FETCH_SUCCESS'

/**
 * Fetch product list action.
 * 
 * @return {Object}
 */
export const fetchProducts = () => {
    return (dispatch) => {
        dispatch(startProductListFetching())
        return api.get()
            .then((data) => {
                dispatch(productListFetchSuccess(data))
            }).catch((error) => {
                dispatch(productListFetchFail(error))
            })
    }
}

/**
 * Product list fetch fail action.
 * 
 * @param {Object} error - Error object
 * @return {Object}
 */
const productListFetchFail = (error) => ({
    type: PRODUCT_LIST_FETCH_FAILED,
    payload: {
        error
    }
})

/**
 * Product list fetch success action.
 * 
 * @return {Object}
 */
const productListFetchSuccess = (products) => ({
    type: PRODUCT_LIST_FETCH_SUCCESS,
    payload: {
        products
    }
})

/**
 * Start product list fetching action.
 * 
 * @return {Object}
 */
const startProductListFetching = () => ({
    type: PRODUCT_LIST_FETCH_IN_PROGRESS
})
