import store from '../store/store'

export const languages = {
    en: {
        abbreviation: 'en',
        default: true,
        name: 'English',
        translationKey: 'languages.in-english'
    },
    fi: {
        abbreviation: 'fi',
        name: 'Finnish',
        translationKey: 'languages.in-finnish'
    },
    sv: {
        abbreviation: 'sv',
        name: 'Swedish',
        translationKey: 'languages.in-swedish'
    },
    no: {
        abbreviation: 'no',
        name: 'Norwegian',
        translationKey: 'languages.in-norwegian'
    },
    da: {
        abbreviation: 'da',
        name: 'Danish',
        translationKey: 'languages.in-danish'
    },
    ko: {
        abbreviation: 'ko',
        name: 'Korean',
        translationKey: 'languages.in-korean'
    }
}

/**
 * Change selection of the provided language.
 * 
 * @param {Object} list - Object containing language selections
 * @param {string} abbreviation - Language abbreviation definition
 * @param {boolean} enabled - Enabled state
 * @return {Object} - Language selection object
 */
export const changeSelection = (list, abbreviation, enabled) => {
    if (
        abbreviation in list &&
        (
            enabled ||
            (
                !isDefault(abbreviation) &&
                !isMain(abbreviation)
            )
        )
    ) {
        list[abbreviation] = enabled
    }

    return list
}

/**
 * Combine array of key value pairs by key containing initial values for each language.
 * 
 * @param {string} key - Base key
 * @param {*} mainValue - Value for main and default language
 * @return {Array} - Translation field key value pairs in array format
 */
export const combineTranslationFieldKeyValuePairs = (key, mainValue = null) => {
    const list = []
    
    Object.values(languages).forEach((language) => {
        list.push([
            key + '_' + language.abbreviation,
            (
                (isDefault(language) || isMain(language)) ?
                mainValue :
                null
            )
        ])
    })

    return list
}

/**
 * Returns initialized language selection object.
 * 
 * @return {Object} - Initial language selection object
 */
export const getInitialSelectionList = () => {
    const list = {}

    Object.values(languages).forEach((language) => {
        list[language.abbreviation] = (
            isDefault(language) ||
            isMain(language)
        )
    })

    return list
}

/**
 * Check if provided language is the default language.
 * 
 * @param {*} language - Language definition
 * @return {boolean} - Is default state
 */
export const isDefault = (language) => {
    return (
        (typeof language === 'object') ?
        (language.default === true) :
        (language in languages && languages[language].default === true)
    )
}

/**
 * Check if provided language is the main language of the brand.
 * 
 * @param {*} language - Language definition
 * @return {boolean} - Is main state
 */
export const isMain = (language) => {
    const mainLanguage = store.getState().language.language

    return (
        (typeof language === 'object') ?
        (language.abbreviation === mainLanguage) :
        (language in languages && language === mainLanguage)
    )
}

/**
 * Check if provided language is selected.
 * 
 * @param {Object} list - Object containing language selections
 * @param {string} language - Language definition
 * @return {boolean} - Is selected state
 */
export const isSelected = (list, language) => {
    return (language in list && list[language] === true)
}
