import { api } from './index'

export const create = (data) => {
    return api.post(
        '/users/',
        data
    )
        .then((response) => {
            return response.data
        })
}

export const deleteUser = (id) => {
    return api.delete('/users/' + id + '/')
        .then((response) => {
            return response.data
        })
}

export const get = () => {
    return api.get('/users/')
        .then((response) => {
            return response.data
        })
}

export const getById = (id) => {
    return api.get('/users/' + id + '/')
        .then((response) => {
            return response.data
        })
}

export const update = (id, data) => {
    return api.patch(
        '/users/' + id + '/',
        data
    )
        .then((response) => {
            return response.data
        })
}
