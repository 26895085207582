import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Spinner } from 'reactstrap'

import { login } from '../../actions/login'

import BasicForm from '../../components/BasicForm'
import Card from '../../components/Card'

import Layout from '../../layout/Layout'

import {
    STATE_LOGIN_IN_PROGRESS,
    STATE_LOGIN_REQUIRED,
    STATE_LOGIN_SUCCESS
} from '../../store/user/user'

const LoginView = (props) => {
    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const { t } = useTranslation()
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.phase === STATE_LOGIN_SUCCESS) {
            props.history.push('/analytics')
        }
    }, [props.history, user.phase])

    const isSubmitable = () => {
        return (
            username !== '' &&
            password !== ''
        )
    }

    const onFieldChange = (key, value) => {
        switch (key) {
            case 'username':
                setUsername(value)
                break
            case 'password':
                setPassword(value)
                break
            default:
                break
        }
    }

    const onLogin = (e) => {
        e.preventDefault()

        dispatch(login({
            username,
            password
        }))
    }

    const processing = (user.phase === STATE_LOGIN_IN_PROGRESS)

    return <Layout hideNavigation>
        <div className="text-center mt-5 mb-4">
          <img style={{width: "10rem"}} alt="EXAKT" src="/exakt-logo.svg" />
        </div>

        <Card
            className="mx-auto px-md-5 shadow"
            bodyClassName="mx-3 pt-5 pb-4"
            style={{maxWidth: "620px"}}
            sm={{ size: 8, offset: 2 }}
            title={ t('login.header') }
        >
            { (user.phase === STATE_LOGIN_REQUIRED && user.error !== null) && (
                <Alert color="warning">
                    {
                        (
                            'response' in user.error &&
                            'data' in user.error.response &&
                            'non_field_errors' in user.error.response.data
                        ) ?
                        user.error.response.data.non_field_errors.join(', ') :
                        t('login.error')
                    }
                </Alert>
            ) }
            <BasicForm
                centerControls
                controls={[
                    <Button
                        className="px-5 mt-3"
                        key="edit"
                        type="submit"
                        color="primary"
                        disabled={ (!isSubmitable() || processing) }
                    >
                        { processing && (
                            <>
                                <Spinner color="secondary" size="sm" />
                                { ' ' }
                            </>
                        )}
                        { t('login.submit') }
                    </Button>
                ]}
                fields={[
                    {
                        disabled: processing,
                        label: t('login.username'),
                        name: 'username',
                        type: 'text',
                        className: "mw-100",
                        value: username
                    },
                    {
                        disabled: processing,
                        label: t('login.password'),
                        name: 'password',
                        type: 'password',
                        className: "mw-100",
                        value: password
                    }
                ]}
                onFieldChange={ onFieldChange }
                onSubmit={ onLogin }
            />
        </Card>
    </Layout>
}

export default LoginView
