import React, { useState } from 'react'
import {
    Button,
    Card as BSCard,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    Col,
    Popover,
    Progress,
    Row
} from 'reactstrap'
import { BiDotsVerticalRounded } from "react-icons/bi"

import CardState from './CardState'

const Card = (props) => {
    const [ popoverOpen, setPopoverOpen ] = useState(false);

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    const controlsClass = props.centerControls ? "card-center-controls" : "card-controls";

    return <Col sm={ props.sm || 3 }>
        <BSCard className={ props.className } style={ props.style }>
            {
                Array.isArray(props.contextMenuControls) && (
                    <div className="card-context-menu" data-testid="card-context-menu">
                        <Button id={ 'CardPopover-' + props.contextMenuId } data-testid="card-context-menu-button" color="secondary" >
                            <BiDotsVerticalRounded />
                        </Button>
                        <Popover placement="bottom" isOpen={ popoverOpen } target={ 'CardPopover-' + props.contextMenuId } toggle={ togglePopover } trigger="click" >
                            { props.contextMenuControls }
                        </Popover>
                    </div>
                )
            }
            {
                props.image && (
                    <div className="card-image-container" data-testid="card-image-container">
                        <CardImg
                            alt={ props.image.alt }
                            data-testid="card-image"
                            src={ props.image.src }
                            top
                        />
                        { props.shadow && <div className="shadow-overlay" /> }
                        {
                            props.image.progress && (
                                <Progress
                                    className="card-progress"
                                    data-testid="card-progress"
                                    color="secondary"
                                    value={ props.image.progress }
                                />
                            )
                        }
                    </div>
                )
            }
            <CardBody className={ props.bodyClassName }>
                <CardTitle data-testid="card-title">{ props.title }</CardTitle>
                <CardText data-testid="card-text">{ props.description }</CardText>
                <div>
                    { props.children }
                </div>
                <Row>
                    {
                        typeof props.isDraft !== 'undefined' && (
                            <CardState isDraft={ props.isDraft } />
                        )
                    }
                    {
                        Array.isArray(props.controls) && (
                            <Col className={ controlsClass } data-testid="card-controls" sm={{ size: 6, offset: ((typeof props.isDraft === 'undefined') ? 6 : 0) }}>
                                { props.controls }
                            </Col>
                        )
                    }
                </Row>
            </CardBody>
        </BSCard>
    </Col>
}

export default Card
