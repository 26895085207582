import { hasPermission, isAccountAdmin, isTryingLogIn } from './permissions'

/**
 * Verify that user meets the route rules
 * 
 * @param {*} rules - Rules set
 * @return {number} - Index of the first rule element that fails the test, returns -1 if every rule passes
 */
export const verifyRouteRules = (rules) => {
    let key = -1

    if (!isTryingLogIn() && Array.isArray(rules)) {
        key = rules.findIndex((rule) => {
            if (typeof rule === 'object') {
                if (
                    (
                        'admin' in rule &&
                        rule.admin === true &&
                        !isAccountAdmin()
                    ) ||
                    (
                        'permission' in rule &&
                        !hasPermission(rule.permission)
                    )
                ) {
                    return true
                }
            }

            return false
        })
    }

    return key
}
