import React from 'react'
import { useTranslation } from 'react-i18next'

import BasicForm from '../BasicForm'

import { languages } from '../../helpers/language'

const LanguageSelection = (props) => {
    const { t } = useTranslation()

    const onFieldChange = (key, value) => {
        props.onSelectionChange(key[1], value)
    }

    return <div className="language-selection">
        <BasicForm
            fields={[
                {
                    subLabels: [
                        t(languages.en.translationKey),
                        t(languages.fi.translationKey),
                        t(languages.sv.translationKey),
                        t(languages.no.translationKey),
                        t(languages.da.translationKey),
                        t(languages.ko.translationKey)
                    ],
                    name: 'languages',
                    type: 'checkbox',
                    value: props.value
                }
            ]}
            onFieldChange={ onFieldChange }
        />
    </div>
}
export default LanguageSelection