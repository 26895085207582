/**
 * Verify that provided url is valid.
 * 
 * @param {string} url - Url
 * @return {boolean} - Is valid url state
 */
export const isValidUrl = (url) => {
    try {
        new URL(url)
    } catch (_) {
        return false
    }

    return true
}
