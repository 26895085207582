import * as api from '../api/choices'

export const CHOICES_FETCH_FAILED = 'choices/CHOICES_FETCH_FAILED'
export const CHOICES_FETCH_IN_PROGRESS = 'choices/CHOICES_FETCH_IN_PROGRESS'
export const CHOICES_FETCH_SUCCESS = 'choices/CHOICES_FETCH_SUCCESS'

/**
 * Fetch choices action.
 * 
 * @return {Object}
 */
export const fetchChoices = () => {
    return (dispatch) => {
        dispatch(startChoicesFetching())
        return api.get()
            .then((data) => {
                dispatch(choicesFetchSuccess(data))
            }).catch((error) => {
                dispatch(choicesFetchFail(error))
            })
    }
}

/**
 * Choices fetch fail action.
 * 
 * @param {Object} error - Error object
 * @return {Object}
 */
const choicesFetchFail = (error) => ({
    type: CHOICES_FETCH_FAILED,
    payload: {
        error
    }
})

/**
 * Choices fetch success action.
 * 
 * @param {Object} choices - Choices object
 * @return {Object}
 */
const choicesFetchSuccess = (choices) => ({
    type: CHOICES_FETCH_SUCCESS,
    payload: {
        choices
    }
})


/**
 * Start choices fetching action.
 * 
 * @return {Object}
 */
const startChoicesFetching = () => ({
    type: CHOICES_FETCH_IN_PROGRESS
})
