import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Row } from 'reactstrap'
import { BiArrowBack } from "react-icons/bi"

const InnerNavigation = (props) => {
    const onBackClick = (e) => {
        if (props.onBackClick) {
            props.onBackClick(e)
        } else {
            props.history.goBack()
        }
    }

    return <Row className="inner-navigation" data-testid="inner-navigation">
        <Button
            data-testid="inner-navigation-back-button"
            color="secondary"
            onClick={ (e) => onBackClick(e) }
        >
            <BiArrowBack />
        </Button>
        { props.children }
    </Row>
}

export default withRouter(InnerNavigation)