import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { Col, Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next'

import MiniAlert from './MiniAlert';
import ConfigController from './ConfigController';
import FilterPicker from './FilterPicker'
import NoDataFound from './NoDataFound';
import Loading from './Loading';

import { ReactComponent as Unique } from '../../images/unique.svg';


const Title = (props) => (
    <span
        className="font-weight-light text-uppercase"
        style={{fontSize: "0.9rem"}}
    >
        {props.children}
    </span>
)


const InfoBox = (props) => {
    const {
        allowUnderDraw,
        children,
        dateFilter,
        error,
        isUniqueContent,
        loading,
        noData,
        overlay,
        setDateFilter,
        subTitle,
        title,
    } = props;

    const { t } = useTranslation()
    const [isHovering, setIsHovering] = useState(false);

    const account = useSelector(state => state.account.account)
    const hasUniqueSubscription = (
        account.subscription_type &&
        account.subscription_type !== "Starter"
    );

    // NOTE: This is a simple hack for getting the map (or any other) widget
    // *under* the header, when we so desire. This oughta be made more dynamic,
    // responsive, etc.
    const mapHax = {zIndex: 3000, width: "237px"};

    const style = allowUnderDraw ? mapHax : {};
    const overflow = allowUnderDraw ? "" : "overflow-auto";

    // Tooltips & popovers need a unique ID to function
    const boxId = title.replace(/ /g, '').replace(/\./g, '').toLowerCase();

    const renderContent = () => {
        if (loading && !error) { return <Loading />; }
        if (noData) { return <NoDataFound />; }

        return children;
    }

    const isBlockedBox = isUniqueContent && !hasUniqueSubscription
    const bgColor = isHovering && isBlockedBox ? "bg-light" : "bg-white";
    const renderCard = () => {
        if (isBlockedBox) {
            if (isHovering) {
                return overlay;
            }

            return (
                <CardBody className={`d-flex flex-column align-items-center justify-content-center p-2`}>
                    <Title>{title}</Title>
                    <Unique />
                </CardBody>
            )
        }


        return (<>
            <CardTitle className="mt-3 mb-2 pl-4 bg-white" style={style}>
                <Title>{title}</Title>
                {error &&
                <MiniAlert message={t("analytics.loading-error")} id={boxId} />
                }
                <span
                    className="position-absolute"
                    style={{right: "1rem", top: "1rem"}}
                >
                    <small className="text-secondary">{subTitle}</small>
                    <ConfigController id={boxId}>
                        <FilterPicker
                            startDate={dateFilter.startDate}
                            endDate={dateFilter.endDate}
                            handleDateChange={setDateFilter}
                        />
                    </ConfigController>
                </span>
            </CardTitle>
            <CardBody className={`text-center align-middle p-2 ${overflow}`}>
                {renderContent()}
                {isUniqueContent &&
                <span
                    className="position-absolute"
                    style={{bottom: "0.4rem", right: "0.75rem"}}
                >
                    <Unique />
                </span>
                }
            </CardBody>
        </>
        )

    };

    return (
        <Col>
            <Card
                className={`${bgColor} border shadow mb-4`}
                style={{height: "300px"}}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {renderCard()}
            </Card>
        </Col>
    );
}

export default InfoBox;
